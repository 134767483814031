import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './CoronavirusFAQ.css';
export var CoronavirusFAQ = function () {
    var t = useTranslation().t;
    return (React.createElement("div", { className: styles.coronavirusFAQ },
        React.createElement("h2", null, t('covidFaq.header', 'Have more questions about COVID-19?')),
        React.createElement("p", null, t('covidFaq.subheader', 'Click one of the topics below to find out more:')),
        React.createElement("a", { href: "https://www.cdc.gov/covid/about/" }, t('covidFaq.links.about', 'About COVID-19')),
        React.createElement("a", { href: "https://www.cdc.gov/covid/long-term-effects/index.html" }, t('covidFaq.links.longCovid', 'Long COVID')),
        React.createElement("a", { href: "https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/how-covid-spreads.html" }, t('covidFaq.links.spread', 'How COVID-19 Spreads')),
        React.createElement("a", { href: "https://www.cdc.gov/covid/testing/index.html#cdc_testing_what_next-interpreting-your-results" }, t('covidFaq.links.positiveTest', 'What To Do If My Test Is Positive?')),
        React.createElement("a", { href: "https://www.cdc.gov/respiratory-viruses/risk-factors/young-children.html" }, t('covidFaq.links.children', 'Children')),
        React.createElement("a", { href: "https://www.cdc.gov/covid/signs-symptoms/index.html" }, t('covidFaq.links.signsSymptoms', 'Symptoms & Emergency Warning Signs')),
        React.createElement("a", { href: "https://www.cdc.gov/covid/testing/index.html" }, t('covidFaq.links.testing', 'Testing')),
        React.createElement("a", { href: "https://www.cdc.gov/covid/vaccines/index.html" }, t('covidFaq.links.vaccines', 'Vaccines')),
        React.createElement("a", { href: "https://www.cdc.gov/coronavirus/2019-ncov/daily-life-coping/animals.html" }, t('covidFaq.links.animals', 'Pets and Animals')),
        React.createElement("a", { href: "https://www.cdc.gov/covid/prevention/index.html" }, t('covidFaq.links.mitigation', 'How to Protect Yourself and Others')),
        React.createElement("a", { href: "https://www.cdc.gov/covid/index.html" }, t('covidFaq.links.moreInfo', 'More Info at the CDC COVID-19 Site'))));
};
