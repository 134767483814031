var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { get, post } from './Axios';
import '../components/clearstep/proto/care_locations_pb';
import '../components/clearstep/proto/results_pb';
import { getAppPath } from '../util/ProxyPath';
import '../components/content_editor/Library/Library';
export var getCostEstimate = function (params) { return __awaiter(void 0, void 0, Promise, function () { return __generator(this, function (_a) {
    return [2 /*return*/, post('/cost_estimate', params, {})];
}); }); };
export var getInsurancePlans = function () { return __awaiter(void 0, void 0, Promise, function () {
    var response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, get("/insurance-plans")];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
        }
    });
}); };
export var getCareLocations = function (params) {
    // Strip data tags from care details
    var processedOptions = params.careOptions.map(function (opt) {
        var baseDetails = opt.careDetails.map(function (detail) { return detail.split('_')[0]; });
        return __assign(__assign({}, opt), { careDetails: baseDetails });
    });
    var searchParams = __assign(__assign({}, params), { careOptions: processedOptions });
    var url = new URL(window.location.href);
    url.pathname = getAppPath('/search_care_locations');
    // Need to use fetch here b/c axios does not support readable streams
    return fetch(url.href, { method: 'POST', body: JSON.stringify(searchParams) });
};
export var bookAppointment = function (req) { return __awaiter(void 0, void 0, Promise, function () { return __generator(this, function (_a) {
    return [2 /*return*/, post('/book_appointment', req, {})];
}); }); };
export var getTags = function (name) { return get("/tags?name=".concat(name)); };
export var getVisitTypes = function (options) { return __awaiter(void 0, void 0, Promise, function () {
    var locale, query;
    return __generator(this, function (_a) {
        locale = options.locale;
        query = '';
        if (locale) {
            query += "?locale=".concat(locale);
        }
        return [2 /*return*/, get("/visit_types".concat(query))];
    });
}); };
var getSchedulesQueryString = function (cls, options) {
    var visitType = options.visitType;
    var query = cls.map(function (cl) { return "ids=".concat(cl.internalId); }).join('&');
    if (visitType) {
        query += "&visitType=".concat(visitType);
    }
    var today = new Date();
    var tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    var formattedToday = today.toISOString().split('T')[0];
    var formattedTomorrow = tomorrow.toISOString().split('T')[0];
    return "?".concat(query, "&startDate=").concat(formattedToday, "&endDate=").concat(formattedTomorrow);
};
export var getSchedules = function (careLocations, options) { return __awaiter(void 0, void 0, Promise, function () {
    var queryString;
    return __generator(this, function (_a) {
        queryString = getSchedulesQueryString(careLocations, options);
        return [2 /*return*/, get("/care_locations/schedules".concat(queryString))];
    });
}); };
