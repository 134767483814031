var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useActor } from '@xstate/react';
import { MetricCard } from '../MetricCard/MetricCard';
import ZeroDefault from './NumberIcons/ZeroDefault.svg';
import OneDefault from './NumberIcons/OneDefault.svg';
import TwoDefault from './NumberIcons/TwoDefault.svg';
import ThreeDefault from './NumberIcons/ThreeDefault.svg';
import FourDefault from './NumberIcons/FourDefault.svg';
import FiveDefault from './NumberIcons/FiveDefault.svg';
import SixDefault from './NumberIcons/SixDefault.svg';
import SevenDefault from './NumberIcons/SevenDefault.svg';
import EightDefault from './NumberIcons/EightDefault.svg';
import NineDefault from './NumberIcons/NineDefault.svg';
import TenDefault from './NumberIcons/TenDefault.svg';
import ZeroSelected from './NumberIcons/ZeroSelected.svg';
import OneSelected from './NumberIcons/OneSelected.svg';
import TwoSelected from './NumberIcons/TwoSelected.svg';
import ThreeSelected from './NumberIcons/ThreeSelected.svg';
import FourSelected from './NumberIcons/FourSelected.svg';
import FiveSelected from './NumberIcons/FiveSelected.svg';
import SixSelected from './NumberIcons/SixSelected.svg';
import SevenSelected from './NumberIcons/SevenSelected.svg';
import EightSelected from './NumberIcons/EightSelected.svg';
import NineSelected from './NumberIcons/NineSelected.svg';
import TenSelected from './NumberIcons/TenSelected.svg';
import ZeroDefaultMobile from './NumberIcons/ZeroDefaultMobile.svg';
import OneDefaultMobile from './NumberIcons/OneDefaultMobile.svg';
import TwoDefaultMobile from './NumberIcons/TwoDefaultMobile.svg';
import ThreeDefaultMobile from './NumberIcons/ThreeDefaultMobile.svg';
import FourDefaultMobile from './NumberIcons/FourDefaultMobile.svg';
import FiveDefaultMobile from './NumberIcons/FiveDefaultMobile.svg';
import SixDefaultMobile from './NumberIcons/SixDefaultMobile.svg';
import SevenDefaultMobile from './NumberIcons/SevenDefaultMobile.svg';
import EightDefaultMobile from './NumberIcons/EightDefaultMobile.svg';
import NineDefaultMobile from './NumberIcons/NineDefaultMobile.svg';
import TenDefaultMobile from './NumberIcons/TenDefaultMobile.svg';
import ZeroSelectedMobile from './NumberIcons/ZeroSelectedMobile.svg';
import OneSelectedMobile from './NumberIcons/OneSelectedMobile.svg';
import TwoSelectedMobile from './NumberIcons/TwoSelectedMobile.svg';
import ThreeSelectedMobile from './NumberIcons/ThreeSelectedMobile.svg';
import FourSelectedMobile from './NumberIcons/FourSelectedMobile.svg';
import FiveSelectedMobile from './NumberIcons/FiveSelectedMobile.svg';
import SixSelectedMobile from './NumberIcons/SixSelectedMobile.svg';
import SevenSelectedMobile from './NumberIcons/SevenSelectedMobile.svg';
import EightSelectedMobile from './NumberIcons/EightSelectedMobile.svg';
import NineSelectedMobile from './NumberIcons/NineSelectedMobile.svg';
import TenSelectedMobile from './NumberIcons/TenSelectedMobile.svg';
import { SelectScore } from '../SelectScore/SelectScore';
import { ScoreSelector } from '../SelectScore/ScoreSelector';
import styles from './NPSCard.css';
import { MetricMachineContext } from '../../Context/MetricFormContext';
import { isSmallScreen } from '../../../util/AppHelpers';
import Textarea from '../../../../tailwind_common/Textarea';
export var NPSCard = function (props) {
    var metricCardProps = props.metricCardProps, score = props.score, onSelect = props.onSelect;
    var metricService = useContext(MetricMachineContext);
    var current = useActor(metricService)[0];
    var message = current.context.message;
    var selectHandler = function (score) {
        onSelect(score);
    };
    var t = useTranslation().t;
    var scores = isSmallScreen()
        ? [
            {
                score: 0,
                defaultIcon: ZeroDefaultMobile,
                hoverIcon: ZeroDefaultMobile,
                selectIcon: ZeroSelectedMobile,
            },
            {
                score: 1,
                defaultIcon: OneDefaultMobile,
                hoverIcon: OneDefaultMobile,
                selectIcon: OneSelectedMobile,
            },
            {
                score: 2,
                defaultIcon: TwoDefaultMobile,
                hoverIcon: TwoDefaultMobile,
                selectIcon: TwoSelectedMobile,
            },
            {
                score: 3,
                defaultIcon: ThreeDefaultMobile,
                hoverIcon: ThreeDefaultMobile,
                selectIcon: ThreeSelectedMobile,
            },
            {
                score: 4,
                defaultIcon: FourDefaultMobile,
                hoverIcon: FourDefaultMobile,
                selectIcon: FourSelectedMobile,
            },
            {
                score: 5,
                defaultIcon: FiveDefaultMobile,
                hoverIcon: FiveDefaultMobile,
                selectIcon: FiveSelectedMobile,
            },
            {
                score: 6,
                defaultIcon: SixDefaultMobile,
                hoverIcon: SixDefaultMobile,
                selectIcon: SixSelectedMobile,
            },
            {
                score: 7,
                defaultIcon: SevenDefaultMobile,
                hoverIcon: SevenDefaultMobile,
                selectIcon: SevenSelectedMobile,
            },
            {
                score: 8,
                defaultIcon: EightDefaultMobile,
                hoverIcon: EightDefaultMobile,
                selectIcon: EightSelectedMobile,
            },
            {
                score: 9,
                defaultIcon: NineDefaultMobile,
                hoverIcon: NineDefaultMobile,
                selectIcon: NineSelectedMobile,
            },
            {
                score: 10,
                defaultIcon: TenDefaultMobile,
                hoverIcon: TenDefaultMobile,
                selectIcon: TenSelectedMobile,
            },
        ]
        : [
            {
                score: 0,
                defaultIcon: ZeroDefault,
                hoverIcon: ZeroSelected,
                selectIcon: ZeroSelected,
            },
            {
                score: 1,
                defaultIcon: OneDefault,
                hoverIcon: OneSelected,
                selectIcon: OneSelected,
            },
            {
                score: 2,
                defaultIcon: TwoDefault,
                hoverIcon: TwoSelected,
                selectIcon: TwoSelected,
            },
            {
                score: 3,
                defaultIcon: ThreeDefault,
                hoverIcon: ThreeSelected,
                selectIcon: ThreeSelected,
            },
            {
                score: 4,
                defaultIcon: FourDefault,
                hoverIcon: FourSelected,
                selectIcon: FourSelected,
            },
            {
                score: 5,
                defaultIcon: FiveDefault,
                hoverIcon: FiveSelected,
                selectIcon: FiveSelected,
            },
            {
                score: 6,
                defaultIcon: SixDefault,
                hoverIcon: SixSelected,
                selectIcon: SixSelected,
            },
            {
                score: 7,
                defaultIcon: SevenDefault,
                hoverIcon: SevenSelected,
                selectIcon: SevenSelected,
            },
            {
                score: 8,
                defaultIcon: EightDefault,
                hoverIcon: EightSelected,
                selectIcon: EightSelected,
            },
            {
                score: 9,
                defaultIcon: NineDefault,
                hoverIcon: NineSelected,
                selectIcon: NineSelected,
            },
            {
                score: 10,
                defaultIcon: TenDefault,
                hoverIcon: TenSelected,
                selectIcon: TenSelected,
            },
        ];
    return (React.createElement(MetricCard, __assign({}, metricCardProps),
        React.createElement(SelectScore, { scoreSelectorProps: {
                scores: scores,
                score: score,
                useOverflow: isSmallScreen(),
                onSelect: selectHandler,
            }, promptText: t('feedbackModal.nps.promptText', 'How likely are you to recommend this digital navigator to your friends and family?'), lowScoreText: t('feedbackModal.nps.lowScoreText', '0-NOT LIKELY'), highScoreText: t('feedbackModal.nps.highScoreText', '10-VERY LIKELY'), ScoreSelectorComponent: ScoreSelector }),
        React.createElement("div", { className: styles.npsMetricCard },
            React.createElement("p", null, t('feedbackModal.nps.question.text', 'Can you please tell us the reason for your scores? (optional)')),
            React.createElement(Textarea, { placeholder: t('feedbackModal.nps.question.placeholder', 'Enter your response, but do not include personal health information.'), onChange: function (e) { return metricService.send({ type: 'SAVE', message: e.target.value }); }, value: message !== null && message !== void 0 ? message : '', id: "npsTextarea" }))));
};
