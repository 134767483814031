import * as React from 'react';
import { twMerge } from 'tailwind-merge';
import '../../clearstep/Types';
import { MessageType } from '../../../util/EventLog';
import { renderExternalLink, renderLink, renderLinkOnSameTab, } from '../../clearstep/util/MarkdownHelpers';
import Markdown from '../../tailwind_common/Markdown/Markdown.Lazy.Wrapped';
var ConversationMessage = function (props) {
    var message = props.message, isCurrentQuestion = props.isCurrentQuestion, scrollRef = props.scrollRef, lineBreakInLink = props.lineBreakInLink, redirectToNewTab = props.redirectToNewTab, isFirstMessage = props.isFirstMessage;
    var text = message.text, is_from_user = message.is_from_user;
    var handleMarkdownLink = function (innerProps) {
        if (innerProps.href.startsWith('tel:+')) {
            // Never render <br /> for telephone link
            return renderLink(innerProps.href, innerProps.children, MessageType.LINK_CLICK, 'text-gray-700 font-normal focus:underline focus:text-gray-400');
        }
        return (React.createElement(React.Fragment, null,
            lineBreakInLink && React.createElement("br", null),
            redirectToNewTab
                ? renderExternalLink(innerProps.href, innerProps.children, MessageType.LINK_CLICK, !is_from_user ? 'no-underline text-link-blue font-normal' : '')
                : renderLinkOnSameTab(innerProps.href, innerProps.children, MessageType.LINK_CLICK, !is_from_user ? 'no-underline text-link-blue font-normal' : '')));
    };
    var renderMessage = function () { return (React.createElement("div", { id: isCurrentQuestion ? 'current_question' : undefined, className: twMerge(is_from_user && 'capitalize-first') },
        React.createElement(Markdown, { options: {
                overrides: {
                    ul: function (innerProps) { return (React.createElement("ul", { className: "pb-5 pl-6 font-normal leading-6" }, innerProps.children)); },
                    p: function (innerProps) { return (React.createElement("p", { className: "font-normal leading-6" }, innerProps.children)); },
                    a: handleMarkdownLink,
                },
            } }, text || ''))); };
    return (React.createElement("div", { className: twMerge('mt-3 max-w-104 px-6 py-4', isFirstMessage && 'mt-0', is_from_user
            ? 'ml-7.5 self-end rounded-bl-2xl rounded-br-none rounded-tl-2xl rounded-tr-2xl bg-chat-color font-normal leading-6 text-white'
            : 'mr-7.5 rounded-bl-none rounded-br-2xl rounded-tl-2xl rounded-tr-2xl bg-bot-light-gray font-normal leading-6 text-gray-700 mobile:mr-0'), ref: scrollRef }, renderMessage()));
};
export default ConversationMessage;
