var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a, _b, _c;
import '../../../../util/GaEvents';
import '../../CareCard/CareCard';
import { buildCareCardFromPropsObject } from '../../CareCard/CareCardBuilder';
import { SourceOfCare } from '../../../clearstep/proto/results_pb';
import '../../../clearstep/proto/theme_pb';
import { filterUnsupportedCareDetails } from '../../../clearstep/util/CareOptions.util';
import { THEME } from '../../../clearstep/util/Theme';
import './BasePartner';
import { SpecialistCareDetail } from '../../../../util/care_details/SpecialistCareDetail';
export var SpecialistTypes;
(function (SpecialistTypes) {
    SpecialistTypes["ALLERGIST"] = "allergist";
    SpecialistTypes["MAMMOGRAM"] = "mammogram";
    SpecialistTypes["CARDIOLOGY"] = "cardiology";
    SpecialistTypes["DERMATOLOGY"] = "dermatology";
    SpecialistTypes["DIABETES"] = "diabetes";
    SpecialistTypes["ENDOCRINOLOGY"] = "endocrinology";
    SpecialistTypes["EXPRESS_CARE"] = "express-care";
    SpecialistTypes["FAMILY_MEDICINE"] = "family-medicine";
    SpecialistTypes["GASTROENTEROLOGY"] = "gastroenterology";
    SpecialistTypes["HEMATOLOGY_ONCOLOGY"] = "hematology-oncology";
    SpecialistTypes["IMMEDIATE_CARE_CENTER"] = "immediate-care-center";
    SpecialistTypes["INTERNAL_MEDICINE"] = "internal-medicine";
    SpecialistTypes["NEPHROLOGY"] = "nephrology";
    SpecialistTypes["NEUROLOGY"] = "neurology";
    SpecialistTypes["OBESITY_MEDICINE"] = "obesity-medicine";
    SpecialistTypes["OBGYN"] = "obstetrics-gynecology";
    SpecialistTypes["OPHTHALMOLOGY"] = "ophthalmology";
    SpecialistTypes["ORTHOPAEDICS"] = "orthopaedics";
    SpecialistTypes["OTOLARYNGOLOGY_ENT"] = "otolaryngology-ent";
    SpecialistTypes["PEDIATRICS"] = "pediatrics";
    SpecialistTypes["PLASTIC_SURGERY"] = "plastic-surgery";
    SpecialistTypes["PULMONARY_MEDICINE"] = "pulmonary-medicine";
    SpecialistTypes["RHEUMATOLOGY"] = "rheumatology";
    SpecialistTypes["SURGERY"] = "surgery";
    SpecialistTypes["UROLOGY"] = "urology";
    SpecialistTypes["VASCULAR_SURGERY"] = "vascular-surgery";
    SpecialistTypes["PHYSICAL_THERAPIST"] = "physical-therapist";
    SpecialistTypes["KNEE_SPECIALIST"] = "knee-specialist";
    SpecialistTypes["SHOULDER_SPECIALIST"] = "shoulder-specialist";
    SpecialistTypes["HAND_SPECIALIST"] = "hand-specialist";
    SpecialistTypes["FOOT_SPECIALIST"] = "foot-specialist";
    SpecialistTypes["SPINE_SPECIALIST"] = "spine-specialist";
    SpecialistTypes["FOREARM_SPECIALIST"] = "forearm-specialist";
    SpecialistTypes["HIP_SPECIALIST"] = "hip-specialist";
    SpecialistTypes["GENERAL_ORTHOPEDIC"] = "general-orthopedic";
    SpecialistTypes["IMAGING_TEST"] = "imaging-test";
})(SpecialistTypes || (SpecialistTypes = {}));
export var SpecialistCareTypeDetailToEnumType = {
    allergist: SpecialistTypes.ALLERGIST,
    cardiology: SpecialistTypes.CARDIOLOGY,
    dermatology: SpecialistTypes.DERMATOLOGY,
    diabetes: SpecialistTypes.DIABETES,
    endocrinology: SpecialistTypes.ENDOCRINOLOGY,
    'express-care': SpecialistTypes.EXPRESS_CARE,
    'family-medicine': SpecialistTypes.FAMILY_MEDICINE,
    gastroenterology: SpecialistTypes.GASTROENTEROLOGY,
    'hematology-oncology': SpecialistTypes.HEMATOLOGY_ONCOLOGY,
    'immediate-care-center': SpecialistTypes.IMMEDIATE_CARE_CENTER,
    'internal-medicine': SpecialistTypes.INTERNAL_MEDICINE,
    mammogram: SpecialistTypes.MAMMOGRAM,
    nephrology: SpecialistTypes.NEPHROLOGY,
    neurology: SpecialistTypes.NEUROLOGY,
    'obesity-medicine': SpecialistTypes.OBESITY_MEDICINE,
    'obstetrics-gynecology': SpecialistTypes.OBGYN,
    ophthalmology: SpecialistTypes.OPHTHALMOLOGY,
    orthopaedics: SpecialistTypes.ORTHOPAEDICS,
    'otolaryngology-ent': SpecialistTypes.OTOLARYNGOLOGY_ENT,
    pediatrics: SpecialistTypes.PEDIATRICS,
    'plastic-surgery': SpecialistTypes.PLASTIC_SURGERY,
    'pulmonary-medicine': SpecialistTypes.PULMONARY_MEDICINE,
    rheumatology: SpecialistTypes.RHEUMATOLOGY,
    surgery: SpecialistTypes.SURGERY,
    urology: SpecialistTypes.UROLOGY,
    'vascular-surgery': SpecialistTypes.VASCULAR_SURGERY,
    'physical-therapist': SpecialistTypes.PHYSICAL_THERAPIST,
    'knee-specialist': SpecialistTypes.KNEE_SPECIALIST,
};
export var SpecialistCareTypeDetailToDisplayName = (_a = {},
    _a[SpecialistTypes.ALLERGIST] = 'Allergy, Asthma & Immunology',
    _a[SpecialistTypes.MAMMOGRAM] = 'Annual Screening Mammogram',
    _a[SpecialistTypes.DIABETES] = 'Diabetes Care & Education',
    _a[SpecialistTypes.EXPRESS_CARE] = 'Express Care',
    _a[SpecialistTypes.FAMILY_MEDICINE] = 'Family Medicine',
    _a[SpecialistTypes.GASTROENTEROLOGY] = 'GI specialist / Gastroenterologist',
    _a[SpecialistTypes.HEMATOLOGY_ONCOLOGY] = 'Hematology & Oncology',
    _a[SpecialistTypes.IMMEDIATE_CARE_CENTER] = 'Immediate Care Centers',
    _a[SpecialistTypes.INTERNAL_MEDICINE] = 'Internal Medicine',
    _a[SpecialistTypes.OBESITY_MEDICINE] = 'Obesity Medicine - Weight Loss Clinic',
    _a[SpecialistTypes.OBGYN] = 'OB/GYN',
    _a[SpecialistTypes.OTOLARYNGOLOGY_ENT] = 'ENT (Ear, Nose, and Throat) Specialist',
    _a[SpecialistTypes.PLASTIC_SURGERY] = 'Plastic Surgery',
    _a[SpecialistTypes.PULMONARY_MEDICINE] = 'Pulmonary Medicine',
    _a[SpecialistTypes.VASCULAR_SURGERY] = 'Vascular Surgery',
    _a[SpecialistTypes.PHYSICAL_THERAPIST] = 'Physical Therapy',
    _a[SpecialistTypes.CARDIOLOGY] = 'Cardiologist',
    _a[SpecialistTypes.ORTHOPAEDICS] = 'Orthopaedics',
    _a[SpecialistTypes.KNEE_SPECIALIST] = 'Orthopedic Knee Surgeon',
    _a[SpecialistTypes.SHOULDER_SPECIALIST] = 'Orthopedic Shoulder Surgeon',
    _a[SpecialistTypes.HAND_SPECIALIST] = 'Orthopedic Hand/Wrist Surgeon',
    _a[SpecialistTypes.FOOT_SPECIALIST] = 'Orthopedic Foot/Ankle Surgeon',
    _a[SpecialistTypes.SPINE_SPECIALIST] = 'Orthopedic Spine Specialist',
    _a[SpecialistTypes.FOREARM_SPECIALIST] = 'Orthopedic Arm Surgeon',
    _a[SpecialistTypes.HIP_SPECIALIST] = 'Orthopedic Hip Surgeon',
    _a[SpecialistTypes.GENERAL_ORTHOPEDIC] = 'Orthopedic Surgeon',
    _a[SpecialistTypes.IMAGING_TEST] = 'Imaging Center',
    _a[SpecialistCareDetail.ALLERGY] = 'Allergist',
    _a[SpecialistCareDetail.ANESTHESIOLOGY] = 'Anesthesiologist',
    _a[SpecialistCareDetail.AUDIOLOGY] = 'Audiologist',
    _a[SpecialistCareDetail.BARIATRIC_SURGERY] = 'Bariatric Surgeon',
    _a[SpecialistCareDetail.CANCER_CENTER] = 'Cancer Center',
    _a[SpecialistCareDetail.CARDIAC_SURGERY] = 'Cardiac Surgeon',
    _a[SpecialistCareDetail.CARDIOVASCULAR_CENTER] = 'Cardiovascular Center',
    _a[SpecialistCareDetail.CLINICAL_DECISION_MAKING] = 'Clinical Decision Making',
    _a[SpecialistCareDetail.COLORECTAL_SURGERY] = 'Colorectal Surgeon',
    _a[SpecialistCareDetail.CLINICAL_NUTRITION] = 'Clinical Nutritionist',
    _a[SpecialistCareDetail.CONCIERGE_MEDICINE] = 'Concierge Primary Care',
    _a[SpecialistCareDetail.DENTISTRY] = 'Dentist',
    _a[SpecialistCareDetail.DEPARTMENT_OF_MEDICINE] = 'Department of Medicine',
    _a[SpecialistCareDetail.DEPARTMENT_OF_PEDIATRICS] = 'Department of Pediatrics',
    _a[SpecialistCareDetail.DEPARTMENT_OF_SURGERY] = 'Department of Surgery',
    _a[SpecialistCareDetail.INTERNAL_MEDICINE] = 'Internist or Primary Care Physician',
    _a[SpecialistCareDetail.PEDIATRICS] = 'Pediatrician',
    _a[SpecialistCareDetail.GENERAL_SURGERY] = 'General Surgeon',
    _a[SpecialistCareDetail.EMERGENCY_SERVICES] = 'Emergency Services',
    _a[SpecialistCareDetail.OTOLARYNGOLOGY] = 'Otolaryngologist',
    _a[SpecialistCareDetail.ENDOCRINOLOGY] = 'Endocrinologist',
    _a[SpecialistCareDetail.GASTROENTEROLOGY] = 'Gastroenterologist',
    _a[SpecialistCareDetail.GAMMA_KNIFE] = 'Radiation Oncologist',
    _a[SpecialistCareDetail.GENETICS] = 'Geneticist',
    _a[SpecialistCareDetail.INFECTIOUS_DISEASE] = 'Infectious Disease Specialist',
    _a[SpecialistCareDetail.GERIATRICS] = 'Geriatrician',
    _a[SpecialistCareDetail.HEMATOLOGY_ONCOLOGY] = 'Hematology Oncologist',
    _a[SpecialistCareDetail.HYPERBARIC_MEDICINE] = 'Hyperbaric Medicine Specialist',
    _a[SpecialistCareDetail.NEPHROLOGY] = 'Nephrologist',
    _a[SpecialistCareDetail.NEUROSURGERY] = 'Neurosurgeon',
    _a[SpecialistCareDetail.OCCUPATIONAL_THERAPY] = 'Occupational Therapist',
    _a[SpecialistCareDetail.OPHTHALMOLOGY] = 'Ophthalmologist',
    _a[SpecialistCareDetail.ORTHOTICS_PROSTHETICS] = 'Orthotics Prosthetics Specialist',
    _a[SpecialistCareDetail.PAIN_MANAGEMENT] = 'Pain Management Specialist',
    _a[SpecialistCareDetail.PALLIATIVE_CARE] = 'Palliative Care Specialist',
    _a[SpecialistCareDetail.PATHOLOGY] = 'Pathologist',
    _a[SpecialistCareDetail.PELVIC_FLOOR] = 'Center for Pelvic Health',
    _a[SpecialistCareDetail.PHARMACY] = 'Pharmacist',
    _a[SpecialistCareDetail.PHYSICAL_MEDICINE] = 'Physical Medicine and Rehabilitation Physician',
    _a[SpecialistCareDetail.PHYSICAL_THERAPY] = 'Physical Therapist',
    _a[SpecialistCareDetail.PLASTIC_SURGERY] = 'Plastic Surgeon',
    _a[SpecialistCareDetail.RADIOLOGY] = 'Radiologist',
    _a[SpecialistCareDetail.PSYCHIATRY] = 'Psychiatrist',
    _a[SpecialistCareDetail.PULMONOLOGY] = 'Pulmonologist',
    _a[SpecialistCareDetail.RADIATION_ONCOLOGY] = 'Radiation Oncologist',
    _a[SpecialistCareDetail.RHEUMATOLOGY] = 'Rheumatologist',
    _a[SpecialistCareDetail.SPEECH_THERAPY] = 'Speech Therapy',
    _a[SpecialistCareDetail.SPINE_CENTER] = 'Spine Center',
    _a[SpecialistCareDetail.ACUTE_SURGERY] = 'Acute Surgery',
    _a[SpecialistCareDetail.SURGICAL_ONCOLOGY] = 'Surgical Oncologist',
    _a[SpecialistCareDetail.THORACIC_SURGERY] = 'Thoracic Surgeon',
    _a[SpecialistCareDetail.TRANSPLANT_SURGERY] = 'Transplant Surgeon',
    _a[SpecialistCareDetail.TRAUMA_SURGERY] = 'Trauma Surgeon',
    _a[SpecialistCareDetail.UROLOGY] = 'Urologist',
    _a[SpecialistCareDetail.VASCULAR_SURGERY] = 'Vascular Surgeon',
    _a[SpecialistCareDetail.VOLUNTEER_SERVICES] = 'Volunteer Services',
    _a[SpecialistCareDetail.WEIGHT_WELLNESS] = 'Weight Wellness',
    _a[SpecialistCareDetail.WOUND_CARE] = 'Wound Care',
    _a[SpecialistCareDetail.PODIATRY] = 'Podiatrist',
    _a);
export var CareCardBuilder = function (options) { return ({
    cta: options.cta || 'specialist care',
    url: options.url ||
        'https://www.dupagemedicalgroup.com/locations?care%5B%5D=Immediate%20Care&care%5B%5D=Express%20Care',
}); };
export var SpecialistCareTypeDetailToCareCard = (_b = {},
    _b[SpecialistTypes.ALLERGIST] = CareCardBuilder({
        url: 'https://www.dulyhealthandcare.com/locations?service%5B%5D=Allergy%2C+Asthma+%26+Immunology',
    }),
    _b[SpecialistTypes.CARDIOLOGY] = CareCardBuilder({
        url: 'https://www.dulyhealthandcare.com/locations?service%5B%5D=Cardiology',
    }),
    _b[SpecialistTypes.DERMATOLOGY] = CareCardBuilder({
        url: 'https://www.dulyhealthandcare.com/locations?service%5B%5D=Dermatology&service%5B%5D=Surgical+Dermatology',
    }),
    _b[SpecialistTypes.DIABETES] = CareCardBuilder({
        url: 'https://www.dulyhealthandcare.com/locations?service%5B%5D=Diabetes+Care+%26+Education',
    }),
    _b[SpecialistTypes.ENDOCRINOLOGY] = CareCardBuilder({
        url: 'https://www.dulyhealthandcare.com/locations?service%5B%5D=Endocrinology',
    }),
    _b[SpecialistTypes.EXPRESS_CARE] = CareCardBuilder({
        url: 'https://www.dulyhealthandcare.com/locations?service%5B%5D=Express+Care',
    }),
    _b[SpecialistTypes.FAMILY_MEDICINE] = CareCardBuilder({
        url: 'https://www.dulyhealthandcare.com/locations?service%5B%5D=Family+Medicine',
    }),
    _b[SpecialistTypes.GASTROENTEROLOGY] = CareCardBuilder({
        url: 'https://www.dulyhealthandcare.com/locations?service%5B%5D=Gastroenterology&service%5B%5D=Interventional+Endoscopy',
    }),
    _b[SpecialistTypes.HEMATOLOGY_ONCOLOGY] = CareCardBuilder({
        url: 'https://www.dulyhealthandcare.com/locations?service%5B%5D=Hematology+%26+Oncology',
    }),
    _b[SpecialistTypes.IMMEDIATE_CARE_CENTER] = CareCardBuilder({
        url: 'https://www.dulyhealthandcare.com/locations?service%5B%5D=Immediate+Care+Centers',
    }),
    _b[SpecialistTypes.INTERNAL_MEDICINE] = CareCardBuilder({
        url: 'https://www.dulyhealthandcare.com/locations?service%5B%5D=Internal+Medicine',
    }),
    _b[SpecialistTypes.MAMMOGRAM] = CareCardBuilder({
        url: 'https://www.dulyhealthandcare.com/locations?service%5B%5D=Annual+Screening+Mammogram',
    }),
    _b[SpecialistTypes.NEPHROLOGY] = CareCardBuilder({
        url: 'https://www.dulyhealthandcare.com/locations?service%5B%5D=Nephrology',
    }),
    _b[SpecialistTypes.NEUROLOGY] = CareCardBuilder({
        url: 'https://www.dulyhealthandcare.com/locations?service%5B%5D=Neurology',
    }),
    _b[SpecialistTypes.OBESITY_MEDICINE] = CareCardBuilder({
        url: 'https://www.dulyhealthandcare.com/locations?service%5B%5D=Obesity+Medicine+-+Weight+Loss+Clinic',
    }),
    _b[SpecialistTypes.OBGYN] = CareCardBuilder({
        url: 'https://www.dulyhealthandcare.com/locations?service%5B%5D=Obstetrics+%26+Gynecology',
    }),
    _b[SpecialistTypes.OPHTHALMOLOGY] = CareCardBuilder({
        url: 'https://www.dulyhealthandcare.com/locations?service%5B%5D=Ophthalmology',
    }),
    _b[SpecialistTypes.ORTHOPAEDICS] = CareCardBuilder({
        url: 'https://www.dulyhealthandcare.com/locations?service%5B%5D=General+Orthopaedics',
    }),
    _b[SpecialistTypes.OTOLARYNGOLOGY_ENT] = CareCardBuilder({
        url: 'https://www.dulyhealthandcare.com/locations?service%5B%5D=Otolaryngology+-+ENT',
    }),
    _b[SpecialistTypes.PEDIATRICS] = CareCardBuilder({
        url: 'https://www.dulyhealthandcare.com/locations?service%5B%5D=Pediatrics',
    }),
    _b[SpecialistTypes.PLASTIC_SURGERY] = CareCardBuilder({
        url: 'https://www.dulyhealthandcare.com/locations?service%5B%5D=Plastic+Surgery',
    }),
    _b[SpecialistTypes.PULMONARY_MEDICINE] = CareCardBuilder({
        url: 'https://www.dulyhealthandcare.com/locations?service%5B%5D=Pulmonary+Medicine',
    }),
    _b[SpecialistTypes.RHEUMATOLOGY] = CareCardBuilder({
        url: 'https://www.dulyhealthandcare.com/locations?service%5B%5D=Rheumatology',
    }),
    _b[SpecialistTypes.SURGERY] = CareCardBuilder({
        url: 'https://www.dulyhealthandcare.com/locations?service%5B%5D=Surgery',
    }),
    _b[SpecialistTypes.UROLOGY] = CareCardBuilder({
        url: 'https://www.dulyhealthandcare.com/locations?service%5B%5D=Urology',
    }),
    _b[SpecialistTypes.VASCULAR_SURGERY] = CareCardBuilder({
        url: 'https://www.dulyhealthandcare.com/locations?service%5B%5D=Vascular+Surgery',
    }),
    _b[SpecialistTypes.PHYSICAL_THERAPIST] = CareCardBuilder({
        url: 'https://www.dulyhealthandcare.com/schedule/physical-occupational-therapy',
    }),
    _b[SpecialistTypes.KNEE_SPECIALIST] = CareCardBuilder({
        url: 'https://www.dulyhealthandcare.com/locations?service%5B%5D=General+Orthopaedics',
    }),
    _b);
export var SpecialistCareDetailToDescription = (_c = {},
    _c[SpecialistTypes.KNEE_SPECIALIST] = 'Find an orthopedic surgeon who specializes or is experienced in treating knee conditions.',
    _c[SpecialistTypes.SHOULDER_SPECIALIST] = 'Find an orthopedic surgeon who specializes or is experienced in treating shoulder conditions.',
    _c[SpecialistTypes.HAND_SPECIALIST] = 'Find an orthopedic surgeon who specializes or is experienced in treating hand and wrist conditions.',
    _c[SpecialistTypes.FOOT_SPECIALIST] = 'Find an orthopedic surgeon who specializes or is experienced in treating foot and ankle conditions.',
    _c[SpecialistTypes.FOREARM_SPECIALIST] = 'Find an orthopedic surgeon who specializes or is experienced in treating conditions of the arm.',
    _c[SpecialistTypes.HIP_SPECIALIST] = 'Find an orthopedic surgeon who specializes or is experienced in treating hip conditions.',
    _c[SpecialistTypes.IMAGING_TEST] = '',
    _c);
export var isEligibilityResult = function (careType, careDetails) {
    if (careType !== SourceOfCare.Type.SPECIALIST || careDetails.length !== 1) {
        return false;
    }
    return careDetails[0].split('_')[1] === 'eligibility';
};
export var getDetailForRendering = function (careDetails, careCards) {
    var baseDetails = careDetails.map(function (detail) {
        var detailArray = detail.split('_');
        return detailArray[0];
    });
    var supportedDetails = filterUnsupportedCareDetails(SourceOfCare.Type.SPECIALIST, baseDetails, THEME.supportedCareDetails // Protobuf mistypes this map as an array...
    );
    var firstDetail = supportedDetails.find(function (detail) { return detail in careCards; });
    if (!firstDetail) {
        return null;
    }
    return firstDetail;
};
export var genericSpecialistCareOption = function (careDetails, careCards) {
    var firstDetail = getDetailForRendering(careDetails, careCards);
    if (!firstDetail) {
        return null;
    }
    var card = careCards[firstDetail];
    var isDropdown = card.isDropdown;
    return buildCareCardFromPropsObject(__assign(__assign({}, card), { headerClasses: isDropdown ? [] : ['!mb-6 flex gap-3 items-center'], descriptionClasses: ['p-0'] }));
};
