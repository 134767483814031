import React from 'react';
import { logEvent } from '../../../util/EventLog';
import { ExternalLink } from '../../ExternalLink/ExternalLink';
import { getCustomDataWrapper } from '../../tailwind_clearstep/Partners/event_log/EventLogPartnerCustomizations';
import { THEME } from './Theme';
export var renderExternalLink = function (href, children, messageType, className, showIcon) {
    if (className === void 0) { className = ''; }
    if (showIcon === void 0) { showIcon = true; }
    return (React.createElement(ExternalLink, { href: href, onClick: function () { return logEvent(messageType, { name: children, link: href }); }, linkText: children, className: className, showIcon: showIcon }));
};
export var renderLink = function (href, children, messageType, className) {
    var _a;
    if (className === void 0) { className = ''; }
    var logEventData = { name: children, link: href };
    var options = { partner: THEME.partner };
    var customDataWrappers = getCustomDataWrapper();
    var key = customDataWrappers[THEME.partner];
    if (key) {
        options.customData = (_a = {},
            _a[key] = {
                // mostly for HCA - we can generalize if any other partner needs this
                title: messageType,
                url: logEventData.link,
                text: logEventData.name,
            },
            _a);
    }
    return (React.createElement("a", { href: href, target: "_blank", rel: "noreferrer", onClick: function () { return logEvent(messageType, logEventData, options); }, className: className }, children));
};
export var renderSup = function (children) { return (React.createElement("sup", { className: "align-super text-xs leading-none" }, children)); };
export var renderLinkOnSameTab = function (href, children, messageType, className) {
    if (className === void 0) { className = ''; }
    return (React.createElement("a", { href: href, onClick: function () { return logEvent(messageType, { name: children, link: href }); }, className: className }, children));
};
var imageStyles = {
    display: 'block',
    maxWidth: '95%',
    margin: '24px auto', // auto margin centers the image
};
export var renderImage = function (passedProps) { return (React.createElement("img", { title: passedProps.title, alt: passedProps.alt, src: passedProps.src, style: imageStyles })); };
