import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './MetricCard.css';
import { isSmallScreen } from '../../../util/AppHelpers';
import CloseIcon from './CloseIcon';
import Button from '../../../../tailwind_common/Button';
export var MetricCard = function (props) {
    var step = props.step, disableBack = props.disableBack, disableSubmit = props.disableSubmit, children = props.children, nextEvent = props.nextEvent, backEvent = props.backEvent, collapseEvent = props.collapseEvent;
    var lastStep = 3;
    var t = useTranslation().t;
    return (React.createElement("div", { className: styles.metricCard, tabIndex: -1 },
        React.createElement("div", { className: styles.metricCard__container },
            React.createElement("div", { className: styles.metricCard__header },
                React.createElement("h5", null, t('common.giveFeedbackLowerF', 'Give feedback')),
                React.createElement("button", { onClick: collapseEvent },
                    React.createElement(CloseIcon, null))),
            children,
            !isSmallScreen() && (React.createElement("div", { className: styles.metricCard__footer },
                React.createElement("div", null,
                    React.createElement("span", { className: styles.metricCard__step__bold }, t('feedbackModal.stepCounter.1', 'Step {{step}}', { step: step })),
                    ' ',
                    React.createElement("span", { className: styles.metricCard__step }, t('feedbackModal.stepCounter.2', 'of')),
                    ' ',
                    React.createElement("span", { className: styles.metricCard__step__bold }, lastStep)),
                React.createElement("div", { className: styles.metricCard__buttonContainer },
                    React.createElement(Button, { secondary: true, disabled: disableBack, onClick: backEvent }, t('feedbackModal.previousStepCta', 'Back')),
                    React.createElement(Button, { onClick: nextEvent, disabled: disableSubmit, ariaLabel: t('feedbackModal.submitAriaLabel', 'Submit score') || '' }, step === lastStep
                        ? t('common.submitCta', 'Submit')
                        : t('feedbackModal.nextStepCta', 'Next'))))),
            isSmallScreen() && (React.createElement("div", { className: styles.metricCard__footer },
                React.createElement(Button, { secondary: true, disabled: disableBack, onClick: backEvent }, t('feedbackModal.previousStepCta', 'Back')),
                React.createElement("div", null,
                    React.createElement("span", { className: styles.metricCard__step__bold }, t('feedbackModal.stepCounter.1', 'Step {{step}}', { step: step })),
                    ' ',
                    React.createElement("span", { className: styles.metricCard__step }, t('feedbackModal.stepCounter.2', 'of')),
                    ' ',
                    React.createElement("span", { className: styles.metricCard__step__bold }, lastStep)),
                React.createElement(Button, { onClick: nextEvent, disabled: disableSubmit, ariaLabel: t('feedbackModal.submitAriaLabel', 'Submit score') || '' }, step === lastStep
                    ? t('common.submitCta', 'Submit')
                    : t('feedbackModal.nextStepCta', 'Next')))))));
};
