import React, { useState, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
export var CheckboxVariant = {
    Default: 0,
    Small: 1,
};
var Checkbox = function (props) {
    var onChange = props.onChange, label = props.label, ariaLabel = props.ariaLabel, padding = props.padding, error = props.error, style = props.style, variant = props.variant, labelClassName = props.labelClassName;
    var checked = props.checked || false;
    var isSmallVariant = variant === CheckboxVariant.Small;
    var paddingText = '';
    switch (padding) {
        case 'small':
            paddingText = 'p-3';
            break;
        case 'medium':
            paddingText = 'p-5';
            break;
        case 'large':
            paddingText = 'p-7';
            break;
        default:
            paddingText = 'p-3';
            break;
    }
    var _a = useState(false), usingKeyboard = _a[0], setKeyboardState = _a[1];
    var setUsingKeyboard = function () {
        if (!usingKeyboard) {
            setKeyboardState(true);
        }
    };
    var unsetUsingKeyboard = function () {
        if (usingKeyboard) {
            setKeyboardState(false);
        }
    };
    useEffect(function () {
        document.addEventListener('keydown', setUsingKeyboard);
        document.addEventListener('mousedown', unsetUsingKeyboard);
        return function () {
            document.removeEventListener('keydown', setUsingKeyboard);
            document.removeEventListener('mousedown', unsetUsingKeyboard);
        };
    }, []);
    var inputAndLabelCss = 'box-border absolute w-6 h-6 border-2 border-solid border-transparent rounded-md left-0';
    var templateSmallVariantCss = 'h-5 w-5';
    return (React.createElement("div", { className: twMerge('w-full', paddingText, 'focus-within:bg-common-bot-light-gray'), style: style || {} },
        React.createElement("label", { className: twMerge('min-h-6 relative block flex cursor-pointer items-center pl-9', isSmallVariant && 'min-h-5 pl-7.5') },
            React.createElement("span", { className: twMerge(inputAndLabelCss, 'border-common-ui-dark-gray', isSmallVariant && "".concat(templateSmallVariantCss, " border-common-ui-dark-gray")) }),
            React.createElement("input", { className: twMerge(inputAndLabelCss, isSmallVariant && templateSmallVariantCss, usingKeyboard && 'focus:shadow-[0_0_2px_2px_outline-blue]'), checked: checked, onChange: onChange, type: "checkbox", "aria-label": ariaLabel !== null && ariaLabel !== void 0 ? ariaLabel : label }),
            React.createElement("span", { className: labelClassName }, label),
            React.createElement("span", { className: twMerge(inputAndLabelCss, 'cursor-pointer border-white bg-center bg-no-repeat opacity-0', isSmallVariant && "".concat(templateSmallVariantCss, " bg-3.25"), checked && 'rounded-md border border-primary bg-checkbox opacity-100') })),
        error && (React.createElement("div", { role: "alert", className: "mt-4 text-sm text-common-tomato" }, error))));
};
export default Checkbox;
