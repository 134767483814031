import React, { useState } from 'react';
import 'i18next';
import { useTranslation } from 'react-i18next';
import Button from './Button';
import TextField from './TextField';
export var zipValidator = function (zip) { return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip); };
var ZipCodeSubmit = function (props) {
    var placeholder = props.placeholder, inputAriaLabel = props.inputAriaLabel, label = props.label, onChange = props.onChange, initialValue = props.initialValue, onSubmit = props.onSubmit, cta = props.cta, error = props.error, buttonAriaLabel = props.buttonAriaLabel;
    var _a = useState(initialValue !== null && initialValue !== void 0 ? initialValue : ''), zip = _a[0], setZip = _a[1];
    var _b = useState(''), validationError = _b[0], setValidationError = _b[1];
    var t = useTranslation().t;
    var handleChange = function (value) {
        setValidationError('');
        setZip(value);
        onChange(value);
    };
    var handleSubmit = function () {
        if (!zipValidator(zip)) {
            setValidationError(t('zipCodeSubmit.errorMessage', 'Please enter a valid zip code'));
            return;
        }
        onSubmit();
    };
    return (React.createElement("div", { className: "flex flex-wrap items-center mobile:flex-col mobile:items-start" },
        React.createElement(TextField, { id: "zip-input", placeholder: placeholder, ariaLabel: inputAriaLabel, label: label, onChange: handleChange, initialValue: initialValue, onEnter: handleSubmit, error: error || validationError, inputMode: "decimal", inputType: "number", required: false, className: "text-semi-base font-bold mobile:w-full", innerClassName: "mobile:w-full" }),
        React.createElement("div", { className: "ml-4 mt-3 mobile:ml-0 mobile:mt-0 mobile:w-full" },
            React.createElement(Button, { onClick: handleSubmit, ariaLabel: buttonAriaLabel, className: "w-45 mobile:w-full" }, cta))));
};
export default ZipCodeSubmit;
