import React from 'react';
import { useTranslation } from 'react-i18next';
import GreenCheck from './GreenCheck.svg';
export var SuccessCard = function () {
    var t = useTranslation().t;
    return (React.createElement("div", { className: "min-w-70 mobile:min-w-auto mb-6 flex min-h-16 flex-row items-start gap-3 rounded-lg bg-common-bg-green px-4 py-2 shadow-success-card mobile:mx-auto mobile:mb-1/50 mobile:w-17/20" },
        React.createElement("img", { src: GreenCheck, alt: "", className: "pt-0.75" }),
        React.createElement("div", { className: "flex flex-col gap-1 pt-2" },
            React.createElement("p", { className: "font-semibold leading-6 text-common-penguin" }, t('feedbackModal.success.header', 'We received your feedback!')),
            React.createElement("p", { className: "text-smoke text-sm leading-5 tracking-wide" }, t('feedbackModal.success.subheader', 'Thank you for helping us improve.')))));
};
