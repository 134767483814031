var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from 'react';
import Markdown from 'markdown-to-jsx';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { prepareText } from '../../clearstep/TextUtil';
import { renderLink } from '../../clearstep/util/MarkdownHelpers';
import { MessageType } from '../../clearstep/proto/logging_pb';
import '../../clearstep/proto/results_pb';
import Slideover from '../../tailwind_common/Slideover';
import { useRingStyle } from '../../tailwind_common/util';
import { InfoCircleIcon } from '../../SVG';
import CareRecSlideoverTitle from '../CareRecommendationSlideoverContent/CareRecSlideoverTitle';
import CareRecSlideoverContent from '../CareRecommendationSlideoverContent/CareRecSlideoverContent';
import './TriageRecommendationeColorMapping';
var alertDiv = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement("div", __assign({}, props, { role: "alert" }), children));
};
var renderSummaryText = function (primaryCareSummary, isProxyUser) {
    var preparedText = prepareText(primaryCareSummary, { isProxyUser: isProxyUser });
    return (React.createElement(Markdown, { options: {
            forceBlock: true,
            overrides: {
                p: function (props) {
                    props.className = 'font-semibold leading-6 text-lg lg:text-2xl lg:leading-8';
                    return alertDiv(props);
                },
                a: function (_a) {
                    var href = _a.href, children = _a.children;
                    return renderLink(href, children, MessageType.CLICKED_PARTNER_CARE_OPTION);
                },
            },
        } }, preparedText));
};
export var TriageRecommendation = function (props) {
    var _a = useState(false), isSlideoverOpen = _a[0], setIsSlideoverOpen = _a[1];
    var triageResponse = props.triageResponse, isProxyUser = props.isProxyUser, theme = props.theme;
    var hideIcon = triageResponse.hideIcon, primaryCareSummary = triageResponse.primaryCareSummary;
    var _b = theme || {}, backgroundColor = _b.backgroundColor, textColor = _b.textColor, Icon = _b.Icon;
    var t = useTranslation().t;
    var ringStyle = useRingStyle();
    return (React.createElement("div", { className: "flex w-full flex-col items-center" },
        React.createElement("div", { className: "w-3/5 tablet-and-mobile:w-9/10" },
            React.createElement("h2", { className: twMerge('mb-6 flex items-end text-3xl text-gray-800 mobile:mb-4 mobile:text-2xl', 'leading-none') },
                t('common.careRecommendation', 'Care Recommendation'),
                React.createElement("button", { className: twMerge('tw-focus ml-2 flex cursor-pointer items-center rounded-full', 'h-6 w-6 focus:ring-offset-0'), style: ringStyle, onClick: function () { return setIsSlideoverOpen(!isSlideoverOpen); } },
                    React.createElement(InfoCircleIcon, { height: "24", width: "24", pathClassName: "stroke-gray-800" }))),
            React.createElement("div", { className: twMerge('flex w-full flex-col rounded-md', backgroundColor, textColor) },
                React.createElement("div", { className: "flex items-center p-5 text-lg font-extrabold leading-none lg:text-2xl", "data-cy": "triageRecommendation" },
                    !hideIcon && Icon && (React.createElement("div", { className: "mr-3" },
                        React.createElement(Icon, null))),
                    renderSummaryText(primaryCareSummary, isProxyUser)))),
        React.createElement(Slideover, { isOpen: isSlideoverOpen, setIsOpen: setIsSlideoverOpen, Title: React.createElement(CareRecSlideoverTitle, null), Content: React.createElement(CareRecSlideoverContent, null) })));
};
export default TriageRecommendation;
