var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useContext, useEffect, useState } from 'react';
import { useActor } from '@xstate/react';
import classNames from 'classnames';
import { InitialCard } from '../Components/InitialCard/InitialCard';
import { MetricMachineContext } from '../Context/MetricFormContext';
import { CollapsedCard } from '../../../tailwind_clearstep/MetricsForm/CollapsedCard';
import '../Components/MetricCard/MetricCard';
import { CSATCard } from '../Components/CSAT/CSATCard';
import { CESCard } from '../Components/CES/CESCard';
import { NPSCard } from '../Components/NPS/NPSCard';
import styles from './MetricController.css';
import { SuccessCard } from '../../../tailwind_clearstep/SuccessCard/SuccessCard';
import { AppFeedback } from '../Components/AppFeedback/AppFeedback';
import { MetricMachineStates } from '../StateMachine/MetricFormMachineTypes';
import { isMobile, isSmallScreen } from '../../util/AppHelpers';
import { getTreatmentWithConfig } from '../../util/Split';
export var MetricController = function () {
    var _a;
    var metricService = useContext(MetricMachineContext);
    var current = useActor(metricService)[0];
    var _b = useState({}), dynamicConfig = _b[0], setDynamicConfig = _b[1];
    useEffect(function () {
        var setupDynamicConfig = function () { return __awaiter(void 0, void 0, void 0, function () {
            var ff;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getTreatmentWithConfig('feedback_category', {})];
                    case 1:
                        ff = _a.sent();
                        if (ff && ff.config) {
                            setDynamicConfig(ff.config);
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        setupDynamicConfig();
    }, []);
    var questions = function () {
        var _a;
        var _b = current.context, question = _b.question, questions = _b.questions;
        var data = current.context.questions[question];
        var metricCardProps = {
            step: 0,
            disableBack: (_a = data === null || data === void 0 ? void 0 : data.disableBack) !== null && _a !== void 0 ? _a : false,
            nextEvent: function () { return metricService.send({ type: 'NEXT', question: data.next }); },
            backEvent: function () { return metricService.send('BACK'); },
            collapseEvent: function () { return metricService.send('COLLAPSE'); },
        };
        var commonProps = {
            onSelect: function (score) {
                metricService.send({ type: 'SAVE', score: score });
            },
            metricCardProps: metricCardProps,
            dynamicConfig: dynamicConfig,
        };
        var userHasAnswered = questions.CSAT.score !== -1 || questions.CES.score !== -1 || questions.NPS.score !== -1;
        switch (question) {
            case 'NPS':
                return (React.createElement(NPSCard, __assign({}, commonProps, { metricCardProps: __assign(__assign({}, metricCardProps), { step: 3, nextEvent: function () { return metricService.send({ type: 'SUBMIT' }); }, disableSubmit: !userHasAnswered }), score: questions.NPS.score })));
            case 'CES':
                return (React.createElement(CESCard, __assign({}, commonProps, { metricCardProps: __assign(__assign({}, metricCardProps), { step: 2 }), score: questions.CES.score })));
            case 'CSAT':
                return (React.createElement(CSATCard, __assign({}, commonProps, { metricCardProps: __assign(__assign({}, metricCardProps), { step: 1 }), score: questions.CSAT.score })));
            default:
                throw new Error('invalid metric state reached');
        }
    };
    var collapseEvent = function () {
        metricService.send('COLLAPSE');
    };
    var saveEvent = function (message) {
        metricService.send({ type: 'SAVE', message: message });
    };
    var submitEvent = function () {
        metricService.send('SUBMIT');
    };
    var isOpen = current.matches(MetricMachineStates.APPFEEDBACK) ||
        current.matches(MetricMachineStates.QUESTION);
    var showCollapsedCard = current.matches(MetricMachineStates.COLLAPSED) &&
        (!isSmallScreen() || current.context.type !== 'app');
    return (React.createElement("div", { className: classNames(styles.metricController, (_a = {},
            _a[styles.metricController__hideBackground] = isOpen && isMobile(),
            _a)) },
        current.matches(MetricMachineStates.APPFEEDBACK) && (React.createElement(AppFeedback, { collapseEvent: collapseEvent, submitEvent: submitEvent, saveEvent: saveEvent })),
        current.matches(MetricMachineStates.EXPANDED) && React.createElement(InitialCard, null),
        showCollapsedCard && React.createElement(CollapsedCard, null),
        current.matches(MetricMachineStates.QUESTION) && questions(),
        current.matches(MetricMachineStates.SUBMITTED) && React.createElement(SuccessCard, null)));
};
