import React, { useContext } from 'react';
import { twMerge } from 'tailwind-merge';
import { useEffect, useRef, useState } from 'preact/hooks';
import { useTranslation } from 'react-i18next';
import { logEvent, MessageType } from '../../../util/EventLog';
import Checkbox, { CheckboxVariant } from '../../tailwind_common/Checkbox';
import { isMobile, isSafari } from '../../clearstep/util/AppHelpers';
import { THEME } from '../../clearstep/util/Theme';
import { stringContainsPersonalInformation } from './PIErrorUtil';
import PIError from './PIError';
import '../../clearstep/proto/conversation_pb';
import SingleSelectQuestion from '../../clearstep/SingleSelectQuestion/SingleSelectQuestion';
import Tooltip from '../../tailwind_common/Tooltip';
import { CircleQuestionMark } from '../../SVG';
import { PartnerCustomizationContext } from '../Partners/symptom_check/PartnerCustomizations';
import ScreenReaderFormGroup from '../../tailwind_common/ScreenReaderFormGroup';
import Button from '../../tailwind_common/Button';
import Textarea from '../../tailwind_common/Textarea';
export var FreeTextInputQuestion = function (props) {
    var initialIsProxyUser = props.initialIsProxyUser, hideProxyUserInput = props.hideProxyUserInput, onSubmit = props.onSubmit, inputId = props.inputId, options = props.options, placeholder = props.placeholder, label = props.label, tooltipHeader = props.tooltipHeader, tooltipContent = props.tooltipContent;
    var t = useTranslation().t;
    var _a = useState(!!initialIsProxyUser), isProxyUser = _a[0], setIsProxyUser = _a[1];
    var _b = useState(''), value = _b[0], setValue = _b[1];
    var _c = useState(false), noSymptomError = _c[0], setNoSymptomError = _c[1];
    var _d = useState(false), piError = _d[0], setPiError = _d[1];
    var textareaRef = useRef(null);
    var partnerCustomizations = useContext(PartnerCustomizationContext);
    // Kind of hacky
    // This focus() function causes an auto scroll/page jump in Safari Mobile, which we don't need b/c all the scroll behavior is handled in Conversation.tsx;
    // We do need this focus function because it makes this item the next tab for users using the keyboard only. However, as there shouldn't be keyboard users on mobile, we can remove it for mobile.
    // Since there is no issue with anything other than Safari, adding that check too to be safe.
    // There is a preventScroll option, but of course no Safari support at this time. If ever added we could update it.
    // */
    var isMobileSafari = isMobile() && isSafari();
    useEffect(function () {
        if (!isMobileSafari) {
            textareaRef.current.focus();
        }
    }, []);
    useEffect(function () {
        logEvent(MessageType.BUTTON_CLICK, {
            isProxyUser: isProxyUser,
        }, { partner: THEME.partner });
    }, [isProxyUser]);
    useEffect(function () {
        if (piError && !stringContainsPersonalInformation(value)) {
            setPiError(false);
        }
    }, [value]);
    var handleSubmit = function () {
        // Abort if we don't have a value
        if (!value) {
            setNoSymptomError(true);
            return;
        }
        if (stringContainsPersonalInformation(value)) {
            setPiError(true);
            return;
        }
        setPiError(false);
        setNoSymptomError(false);
        onSubmit({ value: value, isProxyUser: isProxyUser });
    };
    useEffect(function () {
        var questionId = props.questionId;
        var heap = window.heap;
        if (heap) {
            heap.track('QUESTION_DISPLAYED', {
                questionId: questionId,
            });
        }
    }, []);
    var handleSubmitOption = function (option) {
        if (option) {
            props.onSubmit({ option: option });
        }
    };
    var Label = function () { return (React.createElement("span", { className: "relative flex w-full" },
        React.createElement("span", { className: "mr-1.25" }, label),
        (tooltipHeader || tooltipContent) && (React.createElement(Tooltip, { id: "symptom-question-tooltip", header: tooltipHeader || '', content: tooltipContent || '', Icon: CircleQuestionMark, arrowPosition: partnerCustomizations.symptomQuestionTooltipArrowPosition() })))); };
    return (React.createElement("div", { className: "w-70.5 max-w-[85vw]" },
        React.createElement(ScreenReaderFormGroup, { alert: isMobileSafari, label: label },
            React.createElement(Textarea, { textareaClassName: twMerge('!h-20', noSymptomError || piError ? '!border-common-tomato' : ''), ref: textareaRef, id: inputId, placeholder: placeholder, value: value, onChange: function (e) {
                    setValue(e.target.value);
                }, onKeyDown: function (e) {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        handleSubmit();
                    }
                }, Label: Label }),
            !noSymptomError && !piError && (React.createElement("p", { className: "mt-2 text-sm text-gray-600" }, t('piError.inputInstruction', 'Do not enter personally identifiable information, e.g. Name.'))),
            noSymptomError && (React.createElement("p", { className: "text-xs text-common-tomato" }, t('symptomInput.noSymptomError', 'Please provide a symptom'))),
            piError && React.createElement(PIError, null),
            React.createElement("div", { className: twMerge('mb-0 mt-4 h-px w-full bg-common-bot-medium-gray', noSymptomError && 'mt-0') }),
            !hideProxyUserInput && (React.createElement("div", { className: "pb-6 pl-6 pr-6 pt-4" },
                React.createElement(Checkbox, { checked: isProxyUser, onChange: function (e) {
                        setIsProxyUser(e.target.checked);
                    }, label: t('symptomInput.proxyLabel', 'Answering for someone else') || '', variant: CheckboxVariant.Small })))),
        React.createElement(Button, { disabled: piError, onClick: handleSubmit, className: "w-full" }, t('common.send', 'Send')),
        options && (React.createElement(SingleSelectQuestion, { options: options, handleSelect: handleSubmitOption, key: props.questionId, questionId: props.questionId }))));
};
export default FreeTextInputQuestion;
