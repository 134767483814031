import React, { useEffect, useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';
import styles from './Tailwind.css';
import { XIcon } from '../SVG';
import { isMobile } from '../clearstep/util/AppHelpers';
import { useRingStyle } from './util';
/*
  Slightly hacky, but we need to have the tailwind className as a complete string
  in a component defined in the tailwind.config.js content array
  TODO: When we import tailwind everywhere, we can just pass down the className
*/
export var TooltipArrowPositions;
(function (TooltipArrowPositions) {
    TooltipArrowPositions["SymptomQuestionTooltip"] = "!left-21.5";
    TooltipArrowPositions["SymptomQuestionTooltipMountSinai"] = "!left-24";
    TooltipArrowPositions["SymptomQuestionTooltipNovant"] = "!left-23.25";
    TooltipArrowPositions["AgeQuestionTooltip"] = "md:!left-8.25 !left-9.75";
    TooltipArrowPositions["AgeQuestionTooltipMountSinai"] = "!left-8.75";
})(TooltipArrowPositions || (TooltipArrowPositions = {}));
var Tooltip = function (_a) {
    var Icon = _a.Icon, header = _a.header, content = _a.content, id = _a.id, className = _a.className, arrowPosition = _a.arrowPosition;
    var _b = useState(false), showTooltip = _b[0], setShowTooltip = _b[1];
    // Ensure these are updated together
    var _c = useState(300), animationLength = _c[0], setAnimationLength = _c[1];
    var _d = useState('duration-300'), animationLengthClass = _d[0], setAnimationLengthClass = _d[1];
    var _e = useState(400), animationDelay = _e[0], setAnimationDelay = _e[1];
    useEffect(function () {
        // Tooltip will not close on click in Safari/Firefox mobile without this
        document.addEventListener('mousedown', function () { });
        return function () {
            document.removeEventListener('mousedown', function () { });
        };
    }, []);
    useEffect(function () {
        if (isMobile()) {
            setAnimationLength(0);
            setAnimationLengthClass('');
            setAnimationDelay(0);
        }
    }, []);
    var ringStyle = useRingStyle();
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { "data-tooltip-id": id, className: "tw-focus !w-fit cursor-auto leading-0", onMouseLeave: function () { return setTimeout(function () { return setShowTooltip(false); }, animationDelay); }, style: ringStyle, type: "button", "aria-label": header },
            React.createElement(Icon, { className: "flex" })),
        React.createElement(ReactTooltip, { closeOnEsc: true, delayShow: animationDelay, delayHide: animationDelay + animationLength, id: id, className: twMerge(styles.tooltip, '!left-0 box-border w-full rounded-md px-2.5 py-2 opacity-0 transition-opacity ease-in-out', animationLengthClass, showTooltip && styles.show, className), afterShow: function () {
                setShowTooltip(true);
            }, classNameArrow: arrowPosition },
            React.createElement("div", { className: "relative" },
                isMobile() && React.createElement(XIcon, { className: "absolute right-0 top-0" }),
                React.createElement("div", { className: "mb-1 text-base font-medium" }, header),
                React.createElement("div", { className: "text-sm font-normal text-gray-300" }, content)))));
};
export default Tooltip;
