var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useActor } from '@xstate/react';
import { MetricCard } from '../MetricCard/MetricCard';
import AngryFace from './FaceIcons/AngryFace/AngryFace.svg';
import AngryFaceHover from './FaceIcons/AngryFace/AngryFaceHover.svg';
import AngryFaceSelected from './FaceIcons/AngryFace/AngryFaceSelected.svg';
import AngryFaceMobile from './FaceIcons/AngryFace/AngryFaceMobile.svg';
import AngryFaceSelectedMobile from './FaceIcons/AngryFace/AngryFaceSelectedMobile.svg';
import UnhappyFace from './FaceIcons/UnhappyFace/UnhappyFace.svg';
import UnhappyFaceHover from './FaceIcons/UnhappyFace/UnhappyFaceHover.svg';
import UnhappyFaceSelected from './FaceIcons/UnhappyFace/UnhappyFaceSelected.svg';
import UnhappyFaceMobile from './FaceIcons/UnhappyFace/UnhappyFaceMobile.svg';
import UnhappyFaceSelectedMobile from './FaceIcons/UnhappyFace/UnhappyFaceSelectedMobile.svg';
import ExcitedFace from './FaceIcons/ExcitedFace/ExcitedFace.svg';
import ExcitedFaceHover from './FaceIcons/ExcitedFace/ExcitedFaceHover.svg';
import ExcitedFaceSelected from './FaceIcons/ExcitedFace/ExcitedFaceSelected.svg';
import ExcitedFaceMobile from './FaceIcons/ExcitedFace/ExcitedFaceMobile.svg';
import ExcitedFaceSelectedMobile from './FaceIcons/ExcitedFace/ExcitedFaceSelectedMobile.svg';
import NeutralFace from './FaceIcons/NeutralFace/NeutralFace.svg';
import NeutralFaceHover from './FaceIcons/NeutralFace/NeutralFaceHover.svg';
import NeutralFaceSelected from './FaceIcons/NeutralFace/NeutralFaceSelected.svg';
import NeutralFaceMobile from './FaceIcons/NeutralFace/NeutralFaceMobile.svg';
import NeutralFaceSelectedMobile from './FaceIcons/NeutralFace/NeutralFaceSelectedMobile.svg';
import HappyFace from './FaceIcons/HappyFace/HappyFace.svg';
import HappyFaceHover from './FaceIcons/HappyFace/HappyFaceHover.svg';
import HappyFaceSelected from './FaceIcons/HappyFace/HappyFaceSelected.svg';
import HappyFaceMobile from './FaceIcons/HappyFace/HappyFaceMobile.svg';
import HappyFaceSelectedMobile from './FaceIcons/HappyFace/HappyFaceSelectedMobile.svg';
import { SelectScore } from '../SelectScore/SelectScore';
import { ScoreSelector } from '../SelectScore/ScoreSelector';
import { isSmallScreen } from '../../../util/AppHelpers';
import Textarea from '../../../../tailwind_common/Textarea';
import { MetricMachineContext } from '../../Context/MetricFormContext';
import '../../Controller/MetricController';
export var CSATCard = function (props) {
    var metricCardProps = props.metricCardProps, score = props.score, onSelect = props.onSelect, dynamicConfig = props.dynamicConfig;
    var _a = useState(false), hasLowScore = _a[0], setHasLowScore = _a[1];
    var metricService = useContext(MetricMachineContext);
    var current = useActor(metricService)[0];
    var csatMessage = current.context.csatMessage;
    var t = useTranslation().t;
    var selectHandler = function (score) {
        onSelect(score);
        if (score < 4) {
            // if user selects a low score show the feedback box
            // even if they re-select a higher score
            setHasLowScore(true);
        }
    };
    var scores = isSmallScreen()
        ? [
            {
                score: 1,
                defaultIcon: AngryFaceMobile,
                hoverIcon: AngryFaceSelectedMobile,
                selectIcon: AngryFaceSelectedMobile,
            },
            {
                score: 2,
                defaultIcon: UnhappyFaceMobile,
                hoverIcon: UnhappyFaceSelectedMobile,
                selectIcon: UnhappyFaceSelectedMobile,
            },
            {
                score: 3,
                defaultIcon: NeutralFaceMobile,
                hoverIcon: NeutralFaceSelectedMobile,
                selectIcon: NeutralFaceSelectedMobile,
            },
            {
                score: 4,
                defaultIcon: HappyFaceMobile,
                hoverIcon: HappyFaceSelectedMobile,
                selectIcon: HappyFaceSelectedMobile,
            },
            {
                score: 5,
                defaultIcon: ExcitedFaceMobile,
                hoverIcon: ExcitedFaceSelectedMobile,
                selectIcon: ExcitedFaceSelectedMobile,
            },
        ]
        : [
            {
                score: 1,
                defaultIcon: AngryFace,
                hoverIcon: AngryFaceHover,
                selectIcon: AngryFaceSelected,
            },
            {
                score: 2,
                defaultIcon: UnhappyFace,
                hoverIcon: UnhappyFaceHover,
                selectIcon: UnhappyFaceSelected,
            },
            {
                score: 3,
                defaultIcon: NeutralFace,
                hoverIcon: NeutralFaceHover,
                selectIcon: NeutralFaceSelected,
            },
            {
                score: 4,
                defaultIcon: HappyFace,
                hoverIcon: HappyFaceHover,
                selectIcon: HappyFaceSelected,
            },
            {
                score: 5,
                defaultIcon: ExcitedFace,
                hoverIcon: ExcitedFaceHover,
                selectIcon: ExcitedFaceSelected,
            },
        ];
    return (React.createElement(MetricCard, __assign({}, metricCardProps),
        React.createElement(SelectScore, { scoreSelectorProps: {
                scores: scores,
                score: score,
                onSelect: selectHandler,
            }, ScoreSelectorComponent: ScoreSelector, promptText: (dynamicConfig === null || dynamicConfig === void 0 ? void 0 : dynamicConfig.csat_question) ||
                t('feedbackModal.csat.promptText', 'Please rate your overall satisfaction with this digital navigator.'), lowScoreText: t('feedbackModal.csat.lowScoreText', 'VERY UNSATISFIED'), highScoreText: t('feedbackModal.csat.highScoreText', 'VERY SATISFIED') }),
        hasLowScore && (React.createElement("div", null,
            React.createElement("p", null, t('feedbackModal.csat.question.text', 'Can you please tell us how we can improve? (optional)')),
            React.createElement(Textarea, { placeholder: t('feedbackModal.csat.question.placeholder', 'Enter your response, but do not include personal health information.'), onChange: function (e) {
                    metricService.send({ type: 'SAVE', csatMessage: e.target.value });
                }, value: csatMessage !== null && csatMessage !== void 0 ? csatMessage : '', id: "csatTextarea" })))));
};
