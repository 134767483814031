import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { MetricMachineContext } from '../../clearstep/MetricForm/Context/MetricFormContext';
import { UpArrow } from '../../SVG';
export var CollapsedCard = function () {
    var send = useContext(MetricMachineContext).send;
    var t = useTranslation().t;
    return (React.createElement("button", { onClick: function () { return send('EXPAND'); }, className: twMerge('sticky right-0 top-0 flex h-14 w-48 flex-row items-center justify-around gap-4 rounded-t-lg bg-gray-feedback p-4 mobile:right-2 mobile:justify-between mobile:px-[4%] mobile:py-0', 'mobile:gap-1') },
        React.createElement("h2", { className: "text-base font-extrabold leading-5 tracking-tight text-white" }, t('common.giveFeedbackUpperF', 'Give Feedback')),
        React.createElement(UpArrow, null)));
};
