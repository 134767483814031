import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import StarRatings from 'react-star-ratings';
import { twMerge } from 'tailwind-merge';
import { formatPhoneNumber, mapsDirectionsURL } from '../../clearstep/util/CareLocationHelpers';
import { logEvent } from '../../../util/EventLog';
import { MessageType } from '../../clearstep/proto/logging_pb';
import { InfoIcon } from '../../svg/InfoIcon';
import { LocationPinIcon, PhoneIcon } from '../../SVG';
import { PartnerCustomizationContext } from '../Partners/symptom_check/PartnerCustomizations';
import { THEME } from '../../clearstep/util/Theme';
import '../Partners/symptom_check/BasePartner';
import LinkWithIcon from '../../tailwind_common/LinkWithIcon';
import LocationHours from '../../tailwind_common/LocationHours';
var PartnerCareLocation = function (props) {
    var _a, _b;
    var partnerCustomizations = useContext(PartnerCustomizationContext);
    var getVisitLocationButton = function () {
        var _a = props.location, careType = _a.careType, externalId = _a.externalId, name = _a.name;
        return partnerCustomizations.careLocationButton(careType, {
            externalId: externalId,
            locationName: name,
            link: props.location.link,
        });
    };
    var getVisitAddress = function (locationAddressParams) {
        return partnerCustomizations.careLocationAddress(locationAddressParams);
    };
    var showWebsiteLinkIcon = function () {
        var careType = props.location.careType;
        return partnerCustomizations.showWebsiteLinkIconForCareLocation(careType);
    };
    var getPillText = function () { return partnerCustomizations.getPillText(); };
    var t = useTranslation().t;
    var location = props.location, displayAddress = props.displayAddress, includePhysicianImage = props.includePhysicianImage, defaultImageIcon = props.defaultImageIcon, ctaForName = props.ctaForName, showDefaultLocationHours = props.showDefaultLocationHours;
    var providerName = location.providerName, name = location.name, structuredAddress = location.structuredAddress, phone = location.phone, distance = location.distance, hours = location.hours, link = location.link, waitTime = location.waitTime, starRating = location.starRating, specialty = location.specialty, secondarySpecialties = location.secondarySpecialties;
    var streetNumber = structuredAddress.streetNumber, route = structuredAddress.route, locality = structuredAddress.locality, administrativeAreaLevel1 = structuredAddress.administrativeAreaLevel1, postalCode = structuredAddress.postalCode, room = structuredAddress.room;
    var careLocationAddressParams = {
        streetNumber: streetNumber,
        route: route,
        locality: locality,
        administrativeAreaLevel1: administrativeAreaLevel1,
        postalCode: postalCode,
    };
    var hoursList = hours.split(',');
    var rating = starRating && starRating.length !== 0 ? parseFloat(starRating) : null;
    var seeLocationDetailsLinkText = 'See location details';
    var getDistanceText = "".concat(distance.toFixed(2), " miles away");
    var getDirectionsButtonText = 'Get directions';
    var getDirectionsButtonLink = mapsDirectionsURL(structuredAddress).href;
    var _c = (_a = partnerCustomizations.schedulingLinkConfig(location)) !== null && _a !== void 0 ? _a : {}, schedulingLink = _c.schedulingLink, schedulingLinkImg = _c.schedulingLinkImg;
    var providerExists = !!providerName && providerName.length > 0;
    var columnTW = 'flex flex-col';
    var pTagTW = 'font-semibold tracking-[-0.32px]';
    var specialistElement = function () { return (React.createElement("div", { className: "mt-1" },
        React.createElement("p", { className: "font-medium" }, specialty),
        secondarySpecialties.map(function (specialist) { return (React.createElement("p", { className: "font-medium", key: specialist }, specialist)); }))); };
    return (React.createElement("div", { className: "partnerCareLocation w-full", "data-cy": "partner-care-location" },
        React.createElement("div", { className: "flex flex-row justify-between p-5 mobile:flex mobile:flex-col mobile:items-center mobile:justify-center" },
            React.createElement("div", { className: twMerge(columnTW, 'min-w-70.5 basis-2/5 flex-row pr-4 mobile:mb-4') },
                includePhysicianImage && (React.createElement("div", { className: "mr-4" },
                    React.createElement("div", { className: "h-20 w-20 overflow-hidden rounded-full border border-solid" },
                        React.createElement("img", { src: location.providerImageLink || defaultImageIcon, alt: "Provider icon", className: twMerge('h-full w-full', location.providerImageLink && 'h-28') })))),
                React.createElement("div", null,
                    ctaForName && schedulingLink ? (React.createElement("a", { className: "mb-1.5 text-xl font-extrabold leading-6.5 tracking-[-0.49px] text-primary no-underline hover:underline", href: schedulingLink }, providerExists ? providerName : name)) : (React.createElement("span", { className: "mb-1.5 text-xl font-extrabold leading-6.5 tracking-[-0.49px] text-primary" }, providerExists ? providerName : name)),
                    specialty && specialty.length > 0 && specialistElement(),
                    !!rating && (React.createElement(StarRatings, { rating: rating, starRatedColor: "var(--primary-color)", numberOfStars: 5, name: "Provider Ranking", starDimension: "20px", starSpacing: "2px" })),
                    !!waitTime && (React.createElement("p", null,
                        React.createElement("b", null, t('careLocation.waitLabel', 'Wait: ')),
                        React.createElement("span", null, waitTime))),
                    !!distance && distance > 0 && !displayAddress && (React.createElement("div", { className: "text-decoration-none flex items-center focus:outline-none" },
                        React.createElement("p", { className: "font-normal tracking-[-0.32px]" }, getDistanceText))),
                    !!displayAddress && (React.createElement("div", { className: "text-decoration-none flex flex-col items-start font-normal" },
                        React.createElement("p", { className: pTagTW }, 
                        // eslint-disable-next-line react/jsx-no-literals
                        "".concat(streetNumber, " ").concat(route)),
                        React.createElement("p", { className: pTagTW }, room),
                        React.createElement("p", { className: pTagTW }, 
                        // eslint-disable-next-line react/jsx-no-literals
                        "".concat(locality, ", ").concat(administrativeAreaLevel1, " ").concat(postalCode)))),
                    getVisitAddress(careLocationAddressParams),
                    getPillText() && (React.createElement("div", { className: "box-border self-start rounded-2.5xl bg-primary p-2 text-xs font-semibold text-white" }, getPillText())))),
            React.createElement("div", { className: twMerge(columnTW, 'basis-3/5 pr-4 mobile:mb-4') },
                React.createElement("div", { className: "mb-2 block flex-row" },
                    React.createElement("div", { className: "flex flex-row" },
                        React.createElement(LinkWithIcon, { text: providerExists && name ? name : 'Get Directions', icon: React.createElement(LocationPinIcon, { strokeWidth: "2" }), href: getDirectionsButtonLink, className: "mb-1 hover:underline", pTagClassName: "text-base font-medium tracking-normal", iconClassName: "min-w-3.5", openNewTab: true, onClick: function () {
                                logEvent(MessageType.LINK_CLICK, {
                                    name: getDirectionsButtonText,
                                }, {
                                    partner: THEME.partner,
                                    customData: {
                                        'external-window': {
                                            title: MessageType.LINK_CLICK,
                                            text: getDirectionsButtonText,
                                            url: getDirectionsButtonLink,
                                        },
                                    },
                                });
                            } })),
                    phone && (React.createElement("div", { className: "flex flex-row" },
                        React.createElement(LinkWithIcon, { text: (_b = formatPhoneNumber(phone)) !== null && _b !== void 0 ? _b : '', icon: React.createElement(PhoneIcon, { width: "14", strokeWidth: "2" }), href: "tel:".concat(phone), className: "mb-1 hover:underline", pTagClassName: "text-base font-medium tracking-normal", iconClassName: "min-w-3.5", onClick: function () {
                                logEvent(MessageType.PHONE_NUMBER_CLICK, {
                                    name: phone,
                                }, {
                                    partner: THEME.partner,
                                    customData: {
                                        'external-window': {
                                            title: MessageType.PHONE_NUMBER_CLICK,
                                            phone: phone,
                                        },
                                    },
                                });
                            } }))),
                    React.createElement("div", null,
                        React.createElement("div", { className: "flex flex-col" },
                            React.createElement(LocationHours, { text: "Testing", hoursList: hoursList, showDefaultHours: showDefaultLocationHours }))))),
            React.createElement("div", { className: twMerge(columnTW, 'mt-3 w-full max-w-75 basis-2/5 mobile:max-w-none', getPillText() && 'mobile:mb-4') },
                showWebsiteLinkIcon() && (React.createElement(LinkWithIcon, { text: seeLocationDetailsLinkText, icon: React.createElement(InfoIcon, { fill: "var(--primary-color)" }), href: link, needsLinkReferrer: THEME.needLinkReferrer, onClick: function () {
                        logEvent(MessageType.LINK_CLICK, { name: link }, { partner: THEME.partner });
                    } })),
                schedulingLink && schedulingLinkImg && React.createElement(React.Fragment, null, schedulingLinkImg),
                getVisitLocationButton() && getVisitLocationButton()),
            getPillText() && (React.createElement("div", { className: columnTW },
                React.createElement("div", { className: "none box-border self-start rounded-2.5xl bg-primary p-2 text-xs font-semibold text-white mobile:block" }, getPillText()))))));
};
export default PartnerCareLocation;
