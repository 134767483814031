import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './InitialCard.css';
import { MetricMachineContext } from '../../Context/MetricFormContext';
import ThumbsUpIcon from './ThumbsUpIcon';
import CloseIcon from '../MetricCard/CloseIcon';
import { Partner } from '../../../proto/partners_pb';
import { THEME } from '../../../util/Theme';
import PartnerThumbsUpIcon from './PartnerThumbsUpIcon';
import Button from '../../../../tailwind_common/Button';
export var InitialCard = function () {
    var send = useContext(MetricMachineContext).send;
    var SelectedThumbsUpIcon = THEME.partner === Partner.CLEARSTEP ? React.createElement(ThumbsUpIcon, null) : React.createElement(PartnerThumbsUpIcon, null);
    var t = useTranslation().t;
    return (React.createElement("div", { className: styles.initialCard, tabIndex: -1 },
        React.createElement("div", { className: styles.initialCard__container },
            SelectedThumbsUpIcon,
            React.createElement("button", { onClick: function () { return send('COLLAPSE'); } },
                React.createElement("span", null,
                    React.createElement(CloseIcon, null)))),
        React.createElement("div", { className: styles.initialCard__content },
            React.createElement("p", { className: styles.initialCard__container__text__header }, t('feedbackModal.initialCard.header', 'Help us improve your experience')),
            React.createElement("p", { className: styles.initialCard__container__text__subheader }, t('feedbackModal.initialCard.subheader', 'May we ask you 3 quick questions about your experience? It should take less than 1 minute.'))),
        React.createElement("div", { className: styles.initialCard__buttonContainer },
            React.createElement(Button, { isText: true, onClick: function () { return send('COLLAPSE'); } }, t('feedbackModal.initialCard.closeCta', 'Maybe later')),
            React.createElement(Button, { onClick: function () { return send('GIVE_FEEDBACK'); } },
                ' ',
                t('common.giveFeedbackLowerF', 'Give feedback')))));
};
