var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import 'i18next';
import { Trans, useTranslation } from 'react-i18next';
import 'react';
import { SourceOfCare } from '../../../clearstep/proto/results_pb';
import VirtualVisitOptions from '../../../clearstep/VirtualVisitOptions/VirtualVisitOptions';
import { DisclaimerText } from '../../DisclaimerText/DisclaimerText';
import '../../../clearstep/proto/care_locations_pb';
import { isDemo, useWidgetStyle, isSmallScreen } from '../../../clearstep/util/AppHelpers';
import { getSentenceCase, removeDashes } from '../../../clearstep/util/RenderHelpers';
import SectionHeader from '../../../clearstep/SectionHeader/SectionHeader';
import { VirtualVisitCareOptionIcon } from '../../../svg/VirtualVisitCareOptionIcon';
import { DoctorCareOptionIcon } from '../../../svg/DoctorCareOptionIcon';
import { RetailClinicCareOptionIcon } from '../../../svg/RetailClinicCareOptionIcon';
import { UrgentCareCareOptionIcon } from '../../../svg/UrgentCareCareOptionIcon';
import { DentistCareOptionIcon } from '../../../svg/DentistCareOptionIcon';
import { Partner } from '../../../clearstep/proto/partners_pb';
import { THEME } from '../../../clearstep/util/Theme';
import '../../../clearstep/Types';
import { HomeCareFindPharmacyIcon } from '../../../svg/HomeCareFindPharmacyIcon';
import CareCard from '../../CareCard/CareCard';
import VirtualVisitOptionsForCoronavirus from '../../../clearstep/VirtualVisitOptionsForCoronavirus/VirtualVisitOptionsForCoronavirus';
import { SpecialistCareTypeDetailToDisplayName } from './Specialist.types';
import ResultsCard from '../../ResultsCard/ResultsCard';
import { SpecialistCareDetail } from '../../../../util/care_details/SpecialistCareDetail';
import '../../../../api/ConversationResults';
import { StethoscopeCircleIcon } from '../../../svg/StethoscopeCircleIcon';
import { TooltipArrowPositions } from '../../../tailwind_common/Tooltip';
import '../../../clearstep/proto/address_pb';
var BasePartner = /** @class */ (function () {
    function BasePartner() {
        this.renderInfluenzaVaccineResultsCard = function (fluVaccineCareOption, retailClinicProps) {
            var t = useTranslation().t;
            return (React.createElement(ResultsCard, null,
                React.createElement(CareCard, { title: t('basePartner.fluVaccineCard.title', 'Flu vaccine resources'), headerClasses: ['text-2xl leading-7.5 font-bold mobile:text-1.5xl mobile:leading-6.5'], description: t('basePartner.fluVaccineCard.description', 'Protect yourself and loved ones this season by scheduling a flu shot today'), buttons: [
                        {
                            url: fluVaccineCareOption.url || '',
                            cta: t('basePartner.fluVaccineCard.cta', 'Schedule a flu shot'),
                        },
                    ] })));
        };
        this.renderSuicideLifelineCard = function () {
            var t = useTranslation().t;
            return (React.createElement(CareCard, { buttons: [
                    {
                        url: 'tel:8002738255',
                        cta: t('basePartner.suicideLifelineCard.cta', 'Call now'),
                        lookerTags: {
                            behavioralHealth: '1',
                        },
                    },
                ], description: t('basePartner.suicideLifelineCard.description', 'The National Suicide Prevention Lifeline provides 24/7, free and confidential help for those experiencing a suicidal crisis or emotional distress. They can provide resources for suicide prevention and immediate emotional support, either for yourself or for a loved one.'), noMargin: true }));
        };
        this.behavioralHealthOption = function (options) {
            var t = useTranslation().t;
            return (React.createElement(CareCard, { buttons: [
                    {
                        cta: t('basePartner.behavioralHealthCard.cta', 'Find more information'),
                        url: 'https://www.cdc.gov/mentalhealth/tools-resources/individuals/index.htm',
                        lookerTags: {
                            behavioralHealth: '1',
                        },
                    },
                ], description: t('basePartner.behavioralHealthCard.description', 'The free and confidential resources below can help you or a loved one connect with a skilled, trained mental health professional.'), noMargin: true }));
        };
    }
    BasePartner.prototype.onLoad = function () { };
    BasePartner.prototype.careOptionIcon = function (careType, careDetails) {
        var heightAndWidthSize = isSmallScreen() ? '28' : '56';
        switch (careType) {
            case SourceOfCare.Type.TELEMEDICINE:
                return (React.createElement(VirtualVisitCareOptionIcon, { height: heightAndWidthSize, width: heightAndWidthSize }));
            case SourceOfCare.Type.PRIMARY_CARE:
                return React.createElement(DoctorCareOptionIcon, { height: heightAndWidthSize, width: heightAndWidthSize });
            case SourceOfCare.Type.URGENT_CARE:
                return React.createElement(UrgentCareCareOptionIcon, { height: heightAndWidthSize, width: heightAndWidthSize });
            case SourceOfCare.Type.RETAIL_CLINIC:
                return (React.createElement(RetailClinicCareOptionIcon, { height: heightAndWidthSize, width: heightAndWidthSize }));
            case SourceOfCare.Type.DENTIST:
                return React.createElement(DentistCareOptionIcon, { height: heightAndWidthSize, width: heightAndWidthSize });
            default:
                return React.createElement(DoctorCareOptionIcon, { height: heightAndWidthSize, width: heightAndWidthSize });
        }
    };
    BasePartner.prototype.getSpecialistTitle = function (careDetails, defaultTitle) {
        if (careDetails.length > 0) {
            var baseDetail = careDetails[0].split('_')[0];
            if (baseDetail.indexOf('orthopaedics') > -1) {
                // Many orthopaedics details, search for detail or return 'Orthopaedics' as fallback
                return SpecialistCareTypeDetailToDisplayName[baseDetail] || 'Orthopaedics';
            }
            return (SpecialistCareTypeDetailToDisplayName[baseDetail] ||
                getSentenceCase(removeDashes(baseDetail)));
        }
        return getSentenceCase(defaultTitle);
    };
    BasePartner.prototype.getERFallbackCardCustomizations = function () {
        return {
            title: 'Unable to see or chat with a doctor? Go to the ER now',
            description: 'This is the department in a hospital that provides care right away when you are very sick or badly injured. The emergency room has people and equipment to take care of severe, high risk, and life-threatening problems. No appointment is needed, but remember that the sickest patients are seen first. The other name for emergency room is emergency department.',
        };
    };
    BasePartner.prototype.erFallbackOverride = function () {
        return null;
    };
    BasePartner.prototype.getERCardCustomizations = function (primaryCareDescription) {
        return {
            description: primaryCareDescription,
            icon: React.createElement(StethoscopeCircleIcon, null),
        };
    };
    BasePartner.prototype.emergencyCareOptionOverride = function (soc) {
        return null;
    };
    BasePartner.prototype.getPharmacyCardCustomizations = function () {
        var t = useTranslation().t;
        return {
            title: t('careCards.findPharmacy.title', 'Find a pharmacy'),
            description: t('careCards.findPharmacy.description', 'If you need fever and pain medications, ask someone else who is healthy to pick them up from a pharmacy.'),
            icon: React.createElement(HomeCareFindPharmacyIcon, null),
        };
    };
    BasePartner.prototype.searchCareLocationDefaults = function (triageResponse) {
        return {
            pageSize: 3,
            sort: '',
        };
    };
    BasePartner.prototype.getUrgentCareCardCustomizations = function () {
        return {
            hideFindOtherLocations: false,
        };
    };
    BasePartner.prototype.careOptionTitle = function (options) {
        // If there is exactly one Specialist Type, we should replace the Specialist title with the specific Specialist
        if ((options === null || options === void 0 ? void 0 : options.careType) === SourceOfCare.Type.SPECIALIST) {
            return this.getSpecialistTitle(options.careDetails, options.title);
        }
        if ((options === null || options === void 0 ? void 0 : options.careType) === SourceOfCare.Type.BEHAVIORAL_HEALTH) {
            return 'Behavioral Health Treatment Resources';
        }
        return getSentenceCase(options.title);
    };
    BasePartner.prototype.renderAllCareOptionsCTA = function () {
        return null;
    };
    BasePartner.prototype.findPharmacyHeader = function () {
        return 'Find a pharmacy';
    };
    BasePartner.prototype.findPharmacyDescription = function () {
        return null;
    };
    BasePartner.prototype.showCareOptionDescription = function (options) {
        return true;
    };
    BasePartner.prototype.locationSearchFilters = function (options) {
        return null;
    };
    BasePartner.prototype.showZipcodeInput = function (careOptions) {
        return true;
    };
    BasePartner.prototype.preferredCareOption = function (careOptions) {
        return null;
    };
    BasePartner.prototype.preferredCareOptionSectionTitle = function () {
        return null;
    };
    BasePartner.prototype.pharmacyOptionForHomeTriageRec = function (options) {
        return null;
    };
    BasePartner.prototype.hospitalResource = function () {
        return null;
    };
    BasePartner.prototype.careOptionDescription = function (options) {
        return null;
    };
    BasePartner.prototype.urgentCareOptionMoreInformation = function () {
        return null;
    };
    BasePartner.prototype.supportMultipleCareLocations = function () {
        return false;
    };
    BasePartner.prototype.findCarePlaceholderText = function (careType) {
        if (isDemo()) {
            return '[Enter your zip code](zip-focus) and insurance information above to see options near you';
        }
        return '[Enter your zip code](zip-focus) above to see options near you';
    };
    BasePartner.prototype.virtualVisitOptions = function (options) {
        var t = useTranslation().t;
        return (React.createElement("div", null,
            React.createElement("h5", { className: "mb-4 text-2xl font-semibold leading-7 mobile:text-lg mobile:leading-6.5" }, t('basePartner.virtualVisit.header', 'Virtual visit options')),
            React.createElement(VirtualVisitOptions, null)));
    };
    BasePartner.prototype.retailClinicOption = function (options) {
        return null;
    };
    BasePartner.prototype.retailClinicFallbackOption = function (options) {
        return null;
    };
    BasePartner.prototype.asynchCareOption = function (options) {
        return null;
    };
    BasePartner.prototype.primaryCareOption = function (options) {
        return null;
    };
    BasePartner.prototype.urgentCareOption = function (options) {
        return null;
    };
    BasePartner.prototype.poisonControlOption = function (options) {
        return null;
    };
    BasePartner.prototype.emergencyAdditionalGuidanceOption = function () {
        return null;
    };
    BasePartner.prototype.laborAndDeliveryOption = function () {
        return null;
    };
    BasePartner.prototype.haveCareLocations = function (options) {
        return ((Array.isArray(options === null || options === void 0 ? void 0 : options.careLocations) && !!(options === null || options === void 0 ? void 0 : options.careLocations.length)) ||
            ((options === null || options === void 0 ? void 0 : options.careType) === SourceOfCare.Type.TELEMEDICINE &&
                THEME.partner !== Partner.HCA_TRISTAR));
    };
    BasePartner.prototype.readCareLocationSuccess = function (options) {
        return false;
    };
    BasePartner.prototype.showCareLocationButton = function (careType) {
        return null;
    };
    BasePartner.prototype.showLoading = function (loading, careType) {
        return loading && careType !== SourceOfCare.Type.TELEMEDICINE;
    };
    BasePartner.prototype.homeCareProviderOptionNeedsZipcodeInput = function () {
        return false;
    };
    // Basically render the telemedicine component here UNLESS the partner has another specification/requirement (like HCA)
    BasePartner.prototype.homeCareProviderOption = function (options) {
        var careLocations = options.careLocations, complaint = options.complaint, zipCode = options.zipCode, sourceOfCare = options.sourceOfCare, isNewPatient = options.isNewPatient, loading = options.loading, age = options.age, insuranceStatus = options.insuranceStatus, showCostEstimate = options.showCostEstimate, setZipFocus = options.setZipFocus;
        var careDetails = sourceOfCare.careDetails, careType = sourceOfCare.careType;
        var appointmentScheduling = this.appointmentScheduling({
            careLocations: careLocations,
            complaint: complaint,
            zipCode: zipCode,
            sourceOfCare: sourceOfCare,
            isNewPatient: isNewPatient,
            loading: loading,
            showCostEstimate: showCostEstimate,
            setZipFocus: setZipFocus,
        });
        var showDescription = sourceOfCare.description &&
            this.showCareOptionDescription({
                careType: careType,
                careLocations: careLocations,
                age: age,
                zipCode: zipCode,
                insuranceStatus: insuranceStatus,
                careDetails: careDetails,
            });
        var t = useTranslation().t;
        return (React.createElement(React.Fragment, null,
            React.createElement("h2", null, t('basePartner.homeCareFallback.header', 'Still want to talk to a healthcare provider?')),
            appointmentScheduling ? (React.createElement(React.Fragment, null, this.appointmentScheduling({
                careLocations: careLocations,
                complaint: complaint,
                zipCode: zipCode,
                sourceOfCare: sourceOfCare,
                isNewPatient: isNewPatient,
                loading: loading,
                showCostEstimate: showCostEstimate,
                setZipFocus: setZipFocus,
            }))) : (React.createElement(React.Fragment, null,
                showDescription && React.createElement("p", { className: "pb-8" }, sourceOfCare.description),
                this.virtualVisitOptions({
                    careType: careType,
                    age: age,
                    zipCode: zipCode,
                    insuranceStatus: insuranceStatus,
                    loading: loading,
                    complaint: complaint,
                    sourceOfCare: sourceOfCare,
                    careLocations: careLocations,
                    isNewPatient: isNewPatient,
                    showCostEstimate: showCostEstimate,
                    setZipFocus: setZipFocus,
                })))));
    };
    BasePartner.prototype.vaccineCareOption = function () {
        return null;
    };
    BasePartner.prototype.specialistCareOption = function (options) {
        return null;
    };
    BasePartner.prototype.isAlwaysExpanded = function (careOption) {
        return false;
    };
    BasePartner.prototype.isAlwaysCollapsed = function (careOption) {
        return false;
    };
    BasePartner.prototype.appointmentScheduling = function (options) {
        return null;
    };
    BasePartner.prototype.appointmentSchedulingFallback = function (careType) {
        return null;
    };
    // disclaimer() not used for partners that need translation
    // so we can disable jsx-no-literals for now
    BasePartner.prototype.disclaimer = function () {
        var t = useTranslation().t;
        return (React.createElement("div", { className: "pt-10" },
            React.createElement(SectionHeader, { text: t('common.disclaimerTitle', 'Disclaimer & acknowledgment') }),
            React.createElement(DisclaimerText, null),
            React.createElement(Trans, { t: t, i18nKey: "basePartner.disclaimerLink" },
                React.createElement("div", { className: "pb-4 text-xs font-medium text-dark-gray" },
                    "View",
                    ' ',
                    React.createElement("a", { className: "inline-flex items-center font-semibold text-primary no-underline", href: "https://www.clearstep.health/terms-and-conditions", target: "_blank", rel: "noopener noreferrer" }, "full terms"),
                    "."))));
    };
    BasePartner.prototype.careLocationButton = function (careType, options) {
        return null;
    };
    BasePartner.prototype.careLocationAddress = function (params) {
        return null;
    };
    BasePartner.prototype.getPillText = function () {
        return "";
    };
    BasePartner.prototype.showWebsiteLinkIconForCareLocation = function (careType) {
        return false;
    };
    BasePartner.prototype.careOptionSupported = function (careType, careDetails) {
        return true;
    };
    BasePartner.prototype.behavioralHealthSpecialistOption = function (careDetails) {
        var t = useTranslation().t;
        if (careDetails.includes(SpecialistCareDetail.THERAPIST)) {
            return (React.createElement(CareCard, { buttons: [
                    {
                        cta: t('basePartner.behavioralHealthSpecialistCard.cta', 'Find more information'),
                        url: 'https://www.psychologytoday.com/us/therapists',
                        lookerTags: {
                            behavioralHealth: '1',
                        },
                    },
                ], description: t('basePartner.behavioralHealthSpecialistCard.description', 'You should see or talk to a mental health specialist (like a licensed therapist) for mental health problems that are not emergencies. The therapist may refer you to a primary care physician or a psychiatrist if your health problem has an underlying medical cause or requires more specialized treatment.'), noMargin: true }));
        }
        if (careDetails.includes(SpecialistCareDetail.SUICIDE_LIFELINE)) {
            return this.renderSuicideLifelineCard();
        }
        return null;
    };
    BasePartner.prototype.cardiacEvaluationOption = function () {
        return null;
    };
    BasePartner.prototype.testingResource = function (options) {
        var t = useTranslation().t;
        var buttons = [
            {
                cta: t('basePartner.testingResourcesCard.cta1.text', 'Find a COVID-19 testing site'),
                url: (options === null || options === void 0 ? void 0 : options.url)
                    ? options.url
                    : 'https://www.hhs.gov/coronavirus/community-based-testing-sites/index.html',
            },
        ];
        if (options.isTestToTreat) {
            buttons.push({
                url: 'https://covid-19-test-to-treat-locator-dhhs.hub.arcgis.com/',
                cta: t('basePartner.testingResourcesCard.cta2.text', 'Get tested and treated for COVID-19 at a pharmacy near you'),
            });
        }
        return (React.createElement(CareCard, { title: t('basePartner.testingResourcesCard.title', 'Get tested for COVID-19'), icon: React.createElement(HomeCareFindPharmacyIcon, null), buttons: buttons }));
    };
    BasePartner.prototype.viewCovidTestResultsResource = function () {
        var t = useTranslation().t;
        return (React.createElement(CareCard, { title: t('basePartner.testingResultsCard.title', 'View COVID-19 test results'), heading: "h3", headerClasses: ['text-2xl leading-7.5 font-bold mobile:text-1.5xl mobile:leading-6.5'], icon: React.createElement(HomeCareFindPharmacyIcon, null), description: t('basePartner.testingResultsCard.description', 'Please click the button below to log-in and view your test results.'), buttons: [
                {
                    cta: t('basePartner.testingResultsCard.cta', 'See test results'),
                    url: 'https://mychart.minuteclinic.com/MyChartPRD/Authentication/Login',
                },
            ] }));
    };
    BasePartner.prototype.vaccineResource = function (options) {
        var t = useTranslation().t;
        return (React.createElement(CareCard, { title: t('basePartner.vaccineResourcesCard.title', 'Get the COVID-19 vaccine'), icon: React.createElement(HomeCareFindPharmacyIcon, null), buttons: [
                {
                    cta: t('basePartner.vaccineResourcesCard.cta', 'Find a COVID-19 vaccine site'),
                    url: (options === null || options === void 0 ? void 0 : options.url) ? options.url : 'https://www.vaccines.gov/search/',
                },
            ] }));
    };
    BasePartner.prototype.covidRelatedEmergencyResponse = function () {
        return null;
    };
    BasePartner.prototype.hideAppHeader = function () {
        return useWidgetStyle();
    };
    BasePartner.prototype.covidDoctorTriageResponse = function (options) {
        var t = useTranslation().t;
        var heightAndWidthSize = isSmallScreen() ? '28' : '56';
        return (React.createElement(ResultsCard, null,
            React.createElement("h2", null, t('common.findCareNearYou', 'Find care near you')),
            React.createElement(React.Fragment, null,
                options.hasTestingSupport && (React.createElement(CareCard, { title: t('basePartner.covidDoctorTriage.doctorCareCard.title', 'Speak with a doctor'), icon: React.createElement(VirtualVisitCareOptionIcon, { height: heightAndWidthSize, width: heightAndWidthSize }) })),
                options.hasTelemedicineSupport && React.createElement(VirtualVisitOptionsForCoronavirus, null),
                options.hasTestingSupport && options.isTestToTreat && (React.createElement(CareCard, { useMarginTop: true, buttons: [
                        {
                            url: 'https://covid-19-test-to-treat-locator-dhhs.hub.arcgis.com/',
                            cta: t('basePartner.covidDoctorTriage.getTestedCareCard.cta', 'Get tested and treated for COVID-19 at a pharmacy near you'),
                        },
                    ] })))));
    };
    BasePartner.prototype.renderLatestCovidInformationCard = function () {
        return null;
    };
    BasePartner.prototype.careOptionCost = function (options) {
        return null;
    };
    BasePartner.prototype.isTherapistSupported = function (options) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, false];
            });
        });
    };
    BasePartner.prototype.mixSchedulingAndNonLocations = function (options) {
        return null;
    };
    BasePartner.prototype.careOptionsSubheader = function () {
        return '';
    };
    BasePartner.prototype.bookAppointmentHandler = function (options) {
        return null;
    };
    BasePartner.prototype.schedulingLinkConfig = function (location) {
        return null;
    };
    BasePartner.prototype.shinglesVaccineCareOption = function () {
        return null;
    };
    BasePartner.prototype.tdapVaccineCareOption = function () {
        return null;
    };
    BasePartner.prototype.symptomQuestionTooltipArrowPosition = function () {
        return TooltipArrowPositions.SymptomQuestionTooltip;
    };
    BasePartner.prototype.ageQuestionTooltipArrowPosition = function () {
        return TooltipArrowPositions.AgeQuestionTooltip;
    };
    BasePartner.prototype.renderDentistOption = function (options) {
        return null;
    };
    BasePartner.prototype.enableVisitTypeFilters = function (soc) {
        return false;
    };
    BasePartner.prototype.linkButton = function (url, buttonText, options) {
        return null;
    };
    return BasePartner;
}());
export { BasePartner };
