var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../proto/conversation_pb';
import { HELPER_NAMESPACE, OPTION_NAMESPACE } from '../../../util/i18n/constants';
import { useIsNamespaceEnabled } from '../../../util/i18n/split';
// Passes question content through translation function – selectively applied by split config
var useCurrentQuestion = function (initialQuestion) {
    var i18n = useTranslation().i18n;
    var _a = useState(initialQuestion), questionState = _a[0], setCurrentQuestion = _a[1];
    var optionNsEnabled = useIsNamespaceEnabled(OPTION_NAMESPACE);
    var helperNsEnabled = useIsNamespaceEnabled(HELPER_NAMESPACE);
    if (!i18n.isInitialized) {
        console.error('currentQuestionHook called before i18n init');
        return [questionState, setCurrentQuestion];
    }
    var currentQuestion = __assign({}, questionState);
    if (optionNsEnabled) {
        var localizedOptions = questionState.options.map(function (option) { return (__assign(__assign({}, option), { value: i18n.t(option.value, { ns: OPTION_NAMESPACE }) })); });
        currentQuestion.options = localizedOptions;
    }
    if (helperNsEnabled) {
        var localizedHelpers = questionState.helpers.map(function (helper) {
            var label = helper.label, content = helper.content;
            // account for empty helpers
            if (!(label && content)) {
                return helper;
            }
            return {
                label: i18n.t(label, { ns: HELPER_NAMESPACE }),
                content: i18n.t(content, { ns: HELPER_NAMESPACE }),
            };
        });
        currentQuestion.helpers = localizedHelpers;
    }
    return [currentQuestion, setCurrentQuestion];
};
export default useCurrentQuestion;
