var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { twMerge } from 'tailwind-merge';
import 'i18next';
import React from 'react';
import './ScoreSelector';
export var SelectScore = function (props) {
    var promptText = props.promptText, promptSubtext = props.promptSubtext, lowScoreText = props.lowScoreText, highScoreText = props.highScoreText, ScoreSelectorComponent = props.ScoreSelectorComponent, scoreSelectorProps = props.scoreSelectorProps;
    return (React.createElement("div", { className: "mb-2 w-9/10 mobile:mb-1" },
        React.createElement("div", { className: "mx-0 my-5 mobile:mx-0 mobile:mb-5 mobile:mt-3.5" },
            React.createElement("span", { className: "text-base font-normal leading-6.25 text-common-penguin" }, 
            /* eslint-disable-next-line react/jsx-no-literals */
            "".concat(promptText, " ")),
            React.createElement("span", { className: "text-base font-semibold leading-6.25 text-common-penguin" }, promptSubtext)),
        React.createElement("div", { className: twMerge('flex justify-between', !!scoreSelectorProps.useOverflow && 'flex-col') },
            React.createElement(ScoreSelectorComponent, __assign({}, scoreSelectorProps))),
        React.createElement("div", { className: "my-3 flex justify-between" },
            React.createElement("p", { className: "tracking-tightest text-right text-xs font-normal leading-none text-dark-gray" }, lowScoreText),
            React.createElement("p", { className: "tracking-tightest text-right text-xs font-normal leading-none text-dark-gray" }, highScoreText))));
};
