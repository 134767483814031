var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useEffect, useContext, useReducer } from 'react';
import { ResultStatus, } from '../Types';
import '../proto/conversation_pb';
import { getSearchCareLocationsPartner, isCorona } from '../util/AppHelpers';
import { logEvent, MessageType } from '../../../util/EventLog';
import '../proto/care_locations_pb';
import { zipCodeValidator } from '../util/Validators';
import { SourceOfCare } from '../proto/results_pb';
import SymptomCheckResults from '../SymptomCheckResults/SymptomCheckResults';
import { THEME } from '../util/Theme';
import styles from './ConversationResults.css';
import { getCareLocations } from '../../../api/ConversationResults';
import { MetricMachineContext } from '../MetricForm/Context/MetricFormContext';
import { isEmergencyTriageRec } from '../util/RenderHelpers';
import { npsSubmitted, haveCookie } from '../util/Cookies';
import { PartnerCustomizationContext } from '../../tailwind_clearstep/Partners/symptom_check/PartnerCustomizations';
import { getCacheRequestData, setCacheRequestData } from './searchCareLocationCache';
var zipCodeError = 'Please enter a valid zip code';
var careLocationReducer = function (prevState, action) {
    var _a;
    if (action.initialValue) {
        return action.initialValue;
    }
    return __assign(__assign({}, prevState), (_a = {}, _a[action.type] = action.updatedValue, _a));
};
var ConversationResults = function (props) {
    var metricMachineContext = useContext(MetricMachineContext);
    var partnerCustomizations = useContext(PartnerCustomizationContext);
    var _a = useReducer(careLocationReducer, {}), careLocations = _a[0], dispatchCareLocations = _a[1];
    var _b = useState(''), careLocationsError = _b[0], setCareLocationsError = _b[1];
    var _c = useState(''), userEnterredZipCode = _c[0], setUserEnterredZipCode = _c[1];
    var _d = useState({
        zipCode: userEnterredZipCode || props.recommendedCare.triageResponse.defaultZip || '',
        careTypes: [],
        isNewPatient: true,
        potentiallyInfectious: props.recommendedCare.triageResponse.potentiallyInfectious,
        careOptions: [],
    }), searchCareLocationsRequestData = _d[0], setSearchCareLocationsRequestData = _d[1];
    var setRequestData = function (requestData) {
        setSearchCareLocationsRequestData(requestData);
    };
    var careTypeInCareOptions = function (careType, careOptions) { return careOptions.some(function (careOption) { return careOption.careType === careType; }); };
    var askNps = function (bestCareType) {
        return THEME.askNps && (!isEmergencyTriageRec(bestCareType) || !THEME.hideNpsEmergencyOutcome);
    };
    useEffect(function () {
        var setRecommendedCare = props.setRecommendedCare;
        var defaultRequest = getCacheRequestData(conversationId);
        if (defaultRequest) {
            setRequestData(defaultRequest);
            setRecommendedCare(__assign(__assign({}, recommendedCare), { triageResponse: __assign(__assign({}, recommendedCare.triageResponse), { 
                    /*
                    Ensure tags from cache data are added to triage response.careOptions
                    tags are added as user interacts / selects insurance, but if we are
                    loading from a cache search, this info is not on the triage response
                    */
                    careOptions: defaultRequest.careOptions }) }));
        }
    }, []);
    useEffect(function () {
        var defaultRequest = getCacheRequestData(conversationId);
        if (defaultRequest) {
            searchCareLocations(defaultRequest);
        }
    }, [props.recommendedCare]);
    useEffect(function () {
        var complaint = props.complaint, recommendedCare = props.recommendedCare;
        if (!haveCookie(npsSubmitted) &&
            askNps(recommendedCare.triageResponse.bestCareType) &&
            complaint !== 'baycare-employee-covid-screener') {
            setTimeout(function () { return metricMachineContext.send('INIT'); }, 25000);
        }
        logEvent(MessageType.VIEW_RESULTS, {}, { partner: THEME.partner });
        var heap = window.heap;
        if (heap) {
            heap.track('VIEW_RESULTS', {
                recommendedCareType: recommendedCare.triageResponse.bestCareType,
                primaryCareSummary: recommendedCare.triageResponse.primaryCareSummary,
            });
        }
    }, []);
    var searchCareLocations = function (data, config) {
        var age = props.age, recommendedCare = props.recommendedCare, complaint = props.complaint, conversationId = props.conversationId, externalUserId = props.externalUserId, partnerGroup = props.partnerGroup;
        var isNewPatient = data.isNewPatient;
        var _a = recommendedCare.triageResponse, careOptions = _a.careOptions, potentiallyInfectious = _a.potentiallyInfectious;
        setUserEnterredZipCode(data.zipCode);
        if (!zipCodeValidator(data.zipCode)) {
            setCareLocationsError(zipCodeError);
        }
        else {
            setCareLocationsError('');
        }
        logEvent(MessageType.BUTTON_CLICK, {
            name: 'Submit zip code',
            zipCode: data.zipCode,
            isNewPatient: data.isNewPatient,
        }, { partner: THEME.partner });
        if (!data.zipCode) {
            // No point issuing the query
            return;
        }
        var careTypes = careOptions.map(function (careOption) { return careOption.careType; });
        var extraOpt = {
            title: '',
            subtitle: '',
            rating: 1,
            locationCost: 1,
            url: '',
            careDetails: [],
            isMarkdown: false,
            heading: '',
            buttons: [],
            tags: [],
        };
        if (isCorona(complaint) && !careTypeInCareOptions(SourceOfCare.Type.HOME, careOptions)) {
            // Push home care option to search for pharmacies even if not explicitly specified by json files.
            careOptions.push(__assign(__assign({}, extraOpt), { description: 'search_pharmacy_option', careType: SourceOfCare.Type.HOME }));
        }
        if ((config === null || config === void 0 ? void 0 : config.addERFallback) && !careTypeInCareOptions(SourceOfCare.Type.ER, careOptions)) {
            careOptions.push(__assign(__assign({}, extraOpt), { description: 'er_fallback', careType: SourceOfCare.Type.ER }));
        }
        var careLocationsDisplay = {};
        careTypes.forEach(function (careType) {
            careLocationsDisplay[careType] = ResultStatus.Loading;
        });
        dispatchCareLocations({ type: 'update_all', initialValue: careLocationsDisplay });
        var urlParams = new URLSearchParams(window.location.search);
        var params = __assign({ complaint: complaint, careTypes: careTypes, careOptions: careOptions, isNewPatient: !!isNewPatient, organization: getSearchCareLocationsPartner(), pos: undefined, zipCode: undefined, age: age.toString(), potentiallyInfectious: potentiallyInfectious, convoId: conversationId.toString(), externalUserId: externalUserId, partnerGroup: partnerGroup, bustCache: (urlParams === null || urlParams === void 0 ? void 0 : urlParams.get('bustCache')) === 'true' }, partnerCustomizations.searchCareLocationDefaults(triageResponse));
        params.zipCode = data.zipCode;
        getCareLocations(params).then(function (response) {
            var _a;
            // fetch polyfill doesn't handle streaming the body, so in that case read to completion, then parse.
            if (!response.body) {
                response.text().then(function (responseData) {
                    responseData
                        .split('\n')
                        .filter(function (d) { return d !== ''; })
                        .forEach(function (bytes) { return handleResp(bytes, data.zipCode, careLocationsDisplay); });
                });
                return;
            }
            var reader = (_a = response === null || response === void 0 ? void 0 : response.body) === null || _a === void 0 ? void 0 : _a.getReader();
            var decoder = new TextDecoder();
            var partialData = '';
            // Upon successful response, add request data to the cache to rerun
            // Search if user navigates back to page
            setCacheRequestData(params);
            var read = function () {
                return reader === null || reader === void 0 ? void 0 : reader.read().then(function (result) {
                    var thisData = decoder.decode(result.value || new Uint8Array(), {
                        stream: !result.done,
                    });
                    var parts = thisData.split('\n');
                    partialData += parts[0];
                    if (parts.length > 1) {
                        handleResp(partialData, data.zipCode, careLocationsDisplay);
                        for (var i = 1; i < parts.length - 1; i++) {
                            handleResp(parts[i], data.zipCode, careLocationsDisplay);
                        }
                        partialData = parts[parts.length - 1];
                    }
                    if (result.done) {
                        if (partialData !== '') {
                            throw Error('Partial data streaming');
                        }
                        return;
                    }
                    return read();
                });
            };
            return read();
        });
    };
    var logClosestPartnerLocationForTriageLevel = function (locations, zipCode) {
        if (locations.length > 0) {
            var closestResult = locations[0];
            if (closestResult.partner === THEME.partner && closestResult.distance > 0) {
                logEvent(null, {}, {
                    partner: THEME.partner,
                    customData: {
                        'external-window': {
                            title: MessageType.LOCATION_SEARCH_RESULT,
                            address: zipCode,
                            location: closestResult,
                        },
                    },
                });
            }
        }
    };
    var handleResp = function (bytes, zipCode, careLocationsDisplay) {
        var resp = JSON.parse(bytes);
        if (resp.error) {
            Object.keys(careLocationsDisplay).forEach(function (key) {
                dispatchCareLocations({ type: key, updatedValue: ResultStatus.Error });
            });
            setCareLocationsError(resp.error.indexOf(zipCode) > -1 ? zipCodeError : '');
            return;
        }
        for (var i = 0; i < resp.careLocations.length; i += 1) {
            var entry = resp.careLocations[i];
            dispatchCareLocations({ type: entry.careType, updatedValue: entry.locations });
            logClosestPartnerLocationForTriageLevel(entry.locations, zipCode);
        }
        setCareLocationsError('');
    };
    var humanReadableDiagnosticResources = function (diagnosticResources) {
        var diagnosticString;
        diagnosticString = diagnosticResources.join(', ');
        if (diagnosticResources.length === 1) {
            diagnosticString += ' is recommended';
        }
        else {
            diagnosticString += ' are recommended';
        }
        return diagnosticString;
    };
    var complaint = props.complaint, age = props.age, recommendedCare = props.recommendedCare, conversationId = props.conversationId, isProxyUser = props.isProxyUser, isClearstepEmployee = props.isClearstepEmployee, conversationType = props.conversationType;
    var triageResponse = recommendedCare.triageResponse;
    var diagnosticResources = triageResponse.diagnosticResources;
    return (React.createElement(React.Fragment, null,
        diagnosticResources.length > 0 && (React.createElement("p", { className: styles.conversationResults__diagnosticResources }, humanReadableDiagnosticResources(diagnosticResources))),
        React.createElement(SymptomCheckResults, { conversationId: conversationId, age: age, recommendedCare: recommendedCare, userEnterredZipCode: userEnterredZipCode, careLocations: careLocations, careLocationsError: careLocationsError, onSearchCareLocations: searchCareLocations, complaint: complaint, isClearstepEmployee: isClearstepEmployee, isNewPatient: true, isProxyUser: isProxyUser, conversationType: conversationType, existDiagnosticResources: diagnosticResources.length > 0, searchCareLocationsRequestData: searchCareLocationsRequestData, setRequestData: setRequestData })));
};
export default ConversationResults;
