import 'i18next';
import { useTranslation } from 'react-i18next';
import React, { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
var noop = function () { return null; };
var Textarea = function (props, ref) {
    var value = props.value, placeholder = props.placeholder, Label = props.Label, containerClassName = props.containerClassName, textareaClassName = props.textareaClassName, id = props.id, _a = props.onChange, onChange = _a === void 0 ? noop : _a, _b = props.onBlur, onBlur = _b === void 0 ? noop : _b, _c = props.onKeyDown, onKeyDown = _c === void 0 ? noop : _c;
    var labelExists = !!Label;
    var t = useTranslation().t;
    return (React.createElement("div", { className: "".concat(twMerge('textarea flex flex-col', containerClassName)) },
        labelExists && (React.createElement("label", { className: "text-[16px] leading-[18px] text-common-penguin", htmlFor: id },
            React.createElement(Label, null))),
        React.createElement("textarea", { id: id, value: value, onChange: onChange, className: twMerge('box-border h-full w-full resize-none rounded-lg border border-solid border-ui-dark-gray bg-transparent p-2 font-primary text-[16px] font-normal leading-[21px] text-common-penguin', 'focus:border focus:border-solid focus:border-primary focus:outline-none', 'placeholder-common-text-light-gray', labelExists && 'mt-2.5', textareaClassName), 
            // Using onBlur instead of onChange may help with performance related issues
            onBlur: onBlur, onKeyDown: onKeyDown, ref: ref, placeholder: placeholder || t('textarea.defaultPlaceholder', 'Start typing...') || '' })));
};
export default forwardRef(Textarea);
